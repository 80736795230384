import { Box, BoxProps, Spinner, SpinnerProps, Text } from '@chakra-ui/react'

type LoadingIndicatorProps = {
  customText?: string
  size?: SpinnerProps['size']
} & BoxProps

export const LoadingIndicator = ({
  customText = 'Loading...',
  size = 'xl',
  ...boxProps
}: LoadingIndicatorProps) => {
  return (
    <Box py="12px" {...boxProps}>
      <Spinner size={size} />
      {customText && (
        <Text mt={2} textStyle={size === 'xl' ? 'body-1' : 'body-2'}>
          {customText}
        </Text>
      )}
    </Box>
  )
}
