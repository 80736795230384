import { Box, FormControl, Radio, RadioGroup, Text } from '@chakra-ui/react'
import {
  FormErrorMessage,
  FormLabel,
  Textarea,
} from '@opengovsg/design-system-react'
import { Controller, useFormContext } from 'react-hook-form'

import { EvaluateFormInputs } from '~shared/types/application.dto'

import { REQUIRED_MSG } from '../constant'

const REJECTION_REASONS = [
  'Company does not meet the eligibility criteria of the grant',
  'Company has reach the maximum allowable grant cap/no. of applications',
  'The activity the company has applied for is not supportable under this grant scheme',
  'Your company has previously applied for a similar activity for the same overseas market',
  'Others',
]

export const RejectionQuestionList = () => {
  const {
    control,
    register,
    formState: { errors },
  } = useFormContext<EvaluateFormInputs>()
  const errorMsg = errors['recommendation_reasonForRejection']?.message

  return (
    <Box alignItems={'start'} px="20px" py="16px">
      <FormControl isInvalid={!!errorMsg}>
        <FormLabel isRequired>Reason for rejection</FormLabel>
        <FormErrorMessage>{errorMsg}</FormErrorMessage>
        <Controller
          name={'recommendation_reasonForRejection'}
          control={control}
          rules={{ required: REQUIRED_MSG }}
          render={({ field }) => (
            <RadioGroup
              {...field}
              w="100%"
              borderColor={'utility.feedback.critical'}
              borderWidth={errorMsg ? 1 : undefined}
            >
              {REJECTION_REASONS.map((selection) => {
                return (
                  <Radio key={selection} value={selection}>
                    <Text textAlign={'start'}>{selection}</Text>
                  </Radio>
                )
              })}
            </RadioGroup>
          )}
        />
      </FormControl>
      <FormLabel mt="20px">Remarks</FormLabel>
      <Textarea
        {...register('recommendation_rejection_remarks')}
        placeholder={'Please justify the recommendation for rejection.'}
      />
    </Box>
  )
}
