import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  HStack,
  Text,
  VStack,
} from '@chakra-ui/react'
import { Button, Infobox } from '@opengovsg/design-system-react'
import { UseMutationResult } from '@tanstack/react-query'
import { MdLoop } from 'react-icons/md'

export type RegenerateAutomatedChecksProps = {
  regenerateAutomatedChecksMutation: UseMutationResult<unknown, unknown, void>
}

export const RegenerateAutomatedChecks = ({
  regenerateAutomatedChecksMutation,
}: RegenerateAutomatedChecksProps) => {
  const onRegenerate = () => {
    return regenerateAutomatedChecksMutation.mutate()
  }
  return (
    <Box w={'100%'}>
      <Accordion allowToggle>
        <AccordionItem>
          <Box w={'100%'}>
            <AccordionButton>
              <HStack
                textAlign={'left'}
                justifyContent={'space-between'}
                w={'100%'}
              >
                <Text fontSize={'14px'}>Regenerate Automated Checks</Text>
                <AccordionIcon />
              </HStack>
            </AccordionButton>
            <AccordionPanel>
              <VStack w={'100%'}>
                <Infobox textAlign={'start'} size={'sm'}>
                  Re-runs the automated checks based on initial application data
                  and posts them as an internal note. This should only be used
                  when the automated checks have not already been generated.
                  <br />
                  <br />
                  This action may take some time.
                </Infobox>
                <Button
                  type={'submit'}
                  onClick={onRegenerate}
                  size={'xs'}
                  isLoading={regenerateAutomatedChecksMutation.isLoading}
                  leftIcon={<MdLoop />}
                >
                  Regenerate Checks
                </Button>
              </VStack>
            </AccordionPanel>
          </Box>
        </AccordionItem>
      </Accordion>
    </Box>
  )
}
