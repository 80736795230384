import { Box } from '@chakra-ui/react'
import { Button, useToast } from '@opengovsg/design-system-react'
import { useMutation } from '@tanstack/react-query'
import { useForm } from 'react-hook-form'

import { useZendeskClient } from '~lib/zendeskClient'
import {
  REWORK_TEMPLATES,
  ReworkTemplateCategories,
} from '~shared/constants/application'
import {
  FetchApplicationInfoResp,
  ReworkFormInputs,
} from '~shared/types/application.dto'

import { ReworkSection } from './ReworkSection'

type ReworkTabProps = {
  applicationInfo: FetchApplicationInfoResp
}

export const ReworkTab = ({ applicationInfo }: ReworkTabProps) => {
  const { register, handleSubmit } = useForm<ReworkFormInputs>()
  const { zendeskClient, zendeskRequest } = useZendeskClient()
  const toast = useToast()
  const reworkTemplates = REWORK_TEMPLATES.filter((rt) => {
    if (rt.category !== ReworkTemplateCategories.activity) return true
    return rt.subCategory === applicationInfo.activityType
  })
  const generateReworkTemplateMutation = useMutation({
    mutationFn: (formInputs: ReworkFormInputs) => {
      return zendeskRequest<{ html: string }>(
        `/application/generate-rework-template`,
        'POST',
        formInputs,
      )
    },
    onSuccess: async (resp) => {
      if (zendeskClient) {
        await zendeskClient.invoke('comment.appendHtml', resp.html)
      }
    },
    onError: (error) => {
      console.error('error while generating rework template', error)
      toast({
        title:
          'Something went wrong while generating rework template. Please try again later.',
        status: 'error',
        position: 'bottom',
        isClosable: true,
      })
    },
  })

  const onSubmit = (data: ReworkFormInputs) => {
    return generateReworkTemplateMutation.mutate(data)
  }

  return (
    <form onSubmit={(e) => void handleSubmit(onSubmit)(e)}>
      {reworkTemplates.map(({ category, items }) => {
        return (
          <Box key={category} mb="20px">
            <ReworkSection
              category={category}
              items={items}
              register={register}
            />
          </Box>
        )
      })}
      <Button
        type="submit"
        my="24px"
        isLoading={generateReworkTemplateMutation.isLoading}
      >
        Generate Rework Template
      </Button>
    </form>
  )
}
