import get from 'lodash.get'
import { useEffect, useState } from 'react'
import { FieldError, FieldValues, Path, useFormContext } from 'react-hook-form'

// This hook is used to manage editing the fields of a react-hook-form form.
// It provides functions to toggle edit mode, save changes, and discard changes.
export const useEdit = <TFieldValues extends FieldValues>(
  fieldPath: Path<TFieldValues>,
) => {
  const {
    getValues,
    setValue,
    setError,
    clearErrors,
    formState: { errors },
  } = useFormContext<TFieldValues>()
  const [isEditing, setIsEditing] = useState(false)
  // Store the current value of the field when editing starts so that we can revert back to it if needed
  const [snapshot, setSnapshot] = useState<
    TFieldValues[typeof fieldPath] | undefined
  >()

  useEffect(() => {
    // If the user is in edit mode, set an error to prevent form submission.
    const errorPath: `root.${string}` = `root.${fieldPath}`
    if (isEditing) {
      setError(errorPath, {
        type: 'edit',
        message:
          'You are now in edit mode. Please save or discard your changes before submitting the form.',
      })
    } else {
      const editError = get(errors, errorPath) as FieldError
      if (editError?.type === 'edit') {
        clearErrors(errorPath)
      }
    }
  }, [isEditing, fieldPath, errors, setError, clearErrors])

  // Store the current value of the field when editing starts
  const takeSnapshot = () => {
    const currentValues = getValues(fieldPath)
    setSnapshot(currentValues)
  }

  const toggleEdit = () => {
    if (!isEditing) {
      takeSnapshot()
    }
    setIsEditing(!isEditing)
  }

  // In a react-hook-form, the value is updated in the form context when user modifies the form field.
  // Therefore, onSave doesn't need to do anything except to exit edit mode.
  const onSave = () => {
    setIsEditing(false)
    setSnapshot(undefined)
  }

  // Discard changes and revert back to the value before editing started
  const onDiscard = () => {
    if (snapshot !== undefined) {
      setValue(fieldPath, snapshot, { shouldValidate: true })
    }
    setIsEditing(false)
  }

  return {
    isEditing,
    toggleEdit,
    onSave,
    onDiscard,
  }
}
