import { ReworkTemplates } from '../types/application.dto'

/**
 * The value of this enum is used to determine the type of activity that the company is applying for.
 * It must map to the same value as the Dropdown options in the application form.
 */
export enum ActivityType {
  marketingAndPRActivities = '[OVERSEAS MARKET PROMOTION] Overseas Marketing and PR activities',
  tradeFairs = '[OVERSEAS MARKET PROMOTION] Overseas Physical and Virtual Trade Fairs',
  identificationOfPotentialPartners = '[OVERSEAS BUSINESS DEVELOPMENT] Identification of Potential Overseas Partners',
  inMarketBusinessDevelopment = '[OVERSEAS BUSINESS DEVELOPMENT] In-market Business Development',
  overseasMarketingPresence = '[OVERSEAS BUSINESS DEVELOPMENT] Overseas Marketing Presence',
  ftaAndTradeComplianceConsultancy = '[OVERSEAS MARKET SET-UP] FTA and Trade Compliance Consultancy',
  marketEntry = '[OVERSEAS MARKET SET-UP] Market Entry',
}

export enum ReworkTemplateCategories {
  company = 'Company',
  financialStatements = 'Financial Statements',
  quotations = 'Quotations',
  activity = 'Activity',
}

/**
 * This is a set of fixed templated replies meant for the Rework tab in the custom app.
 */
export const REWORK_TEMPLATES: ReworkTemplates = [
  {
    category: ReworkTemplateCategories.company,
    items: [
      {
        key: 'company_findOutMore',
        label: 'Find out more about the company',
        templateReply:
          'We would like to understand more about your company. Could you provide us with the following information:',
        templateReplySubpoints: [
          'Your current products and services',
          'Your current clients',
          'Your current revenue stream(s)',
          'Your Business Plan',
        ],
      },
      {
        key: 'company_acraBusinessFiles',
        label: 'Request ACRA business files of company',
        templateReply:
          'All applicants applying for the grant must meet the 30% local shareholding eligibility. Could you provide us with the following:',
        templateReplySubpoints: [
          'Your ACRA business files, with listed shareholders and corresponding shareholding of your company',
          'The ACRA business files of your parent company (if applicable)',
          'If there is a long list of shareholders, please provide the information in Excel and highlight the Singaporean/PR percentage in ownership.',
        ],
      },
      {
        key: 'company_majorShareholder',
        label: 'Verify major shareholder ownership',
        templateReply:
          'We note that COMPANY_NAME is the major shareholder of your company. Could you provide us with the following:',
        templateReplySubpoints: [
          'The ACRA business files of COMPANY_NAME, with listed shareholders and corresponding shareholding',
          'If there is a long list of shareholders, please provide the information in Excel and highlight the Singaporean/PR percentage in ownership.',
        ],
      },
      {
        key: 'company_proofOfFinancialCapabilityToCompleteProject',
        label: 'Require proof of financial capability to complete the project',
        templateReply:
          "Could you provide us with supporting documents to demonstrate that the company has the financial capability to complete the project? Note that evidence of the company's financial strengths and the technical team's capability to see through the project are two critical requirements. Do include:",
        templateReplySubpoints: [
          'The latest bank statement showing the current balance of the company',
          'Signed agreement/contract with your clients to prove recurring revenue',
          'Any other documents that you deem useful',
        ],
      },
    ],
  },
  {
    category: ReworkTemplateCategories.financialStatements,
    items: [
      {
        key: 'financialStatements_notAttached',
        label: "Company's Financial Statements is not attached",
        templateReply:
          "We did not receive your company's financial statements for the past 1 year. Please provide your company's financial statements for this period.",
      },
      {
        key: 'financialStatements_notForPast1Year',
        label:
          "Company's Financial Statements is attached but not for the past 1 year",
        templateReply:
          "The financial statements provided are not for the past 1 year. Please provide your company's financial statements for this period.",
      },
      {
        key: 'financialStatements_annualSalesTurnoverDeclaredNotTally',
        label:
          "Company's Annual Sales Turnover declared does not tally with Company's Financial Statements",
        templateReply:
          'The annual sales turnover declared does not tally with the financial statements provided. Could you clarify whether the annual sales turnover declared is accurate?',
      },
    ],
  },
  {
    category: ReworkTemplateCategories.quotations,
    items: [
      {
        key: 'quotations_require3Quotations',
        label: 'Require 3 quotations',
        templateReply:
          'Have you performed a quotation comparison with 2 other vendors? If yes, could you provide these?',
      },
      {
        key: 'quotations_requireBreakdown',
        label: 'Require quotation breakdown',
        templateReply:
          'Could you break down the quotation into smaller line items? The quotation should include the item breakdown, scope of work with deliverables, and cost.',
      },
      {
        key: 'quotations_howDidYouKnowVendor',
        label: 'How did the company know the vendor?',
        templateReply:
          'Could you share more information on how the company got to know the vendor?',
      },
      {
        key: 'quotations_requireVendorCredentials',
        label: 'Require vendor credentials',
        templateReply:
          'Could you provide us with more information on the credentials, portfolio, and track record of your quoted vendor?',
      },
      {
        key: 'quotations_justificationForAppointingVendor',
        label: 'Justification for appointing vendor',
        templateReply:
          'Could you share more information with us on why you have chosen this vendor?',
      },
    ],
  },
  {
    category: ReworkTemplateCategories.activity,
    subCategory: ActivityType.overseasMarketingPresence,
    items: [
      {
        key: 'activity_overseasMarketingPresence_requireClarificationOnHolisticProject',
        label: 'Require clarification on OMP holistic project',
        templateReply:
          "Could you provide us with the vendor's proposal for the campaign? This document should contain:",
        templateReplySubpoints: [
          'Clear summary of the project',
          'The holistic plan',
          'The overall roadmap/communication plan of activities',
          'The timeline/milestone of the project',
          'The deliverables and KPI for each milestone project',
        ],
      },
      {
        key: 'activity_overseasMarketingPresence_confirmRetrospectiveApplication',
        label: 'Confirm retrospective OMP application',
        templateReply: 'Please confirm on the following: ',
        templateReplySubpoints: [
          'Has the company made payment for the office rental before applying for MRA? (only applicable if you are seeking support for rental; otherwise, indicate NA)',
          'Has the company started the project before MRA application date?',
          'Has the company commenced on the project?',
        ],
      },
      {
        key: 'activity_overseasMarketingPresence_BDPermStaff',
        label: 'Provide the following permanent BD staff information',
        templateReply:
          'For permanent BD staff, kindly provide the following information:',
        templateReplySubpoints: [
          'Roles and responsibilities for the position',
          'Appointment letter/contract',
          'CV/qualifications',
        ],
      },
      {
        key: 'activity_overseasMarketingPresence_BDUnidentified',
        label: 'If BD staff unidentified / TBD, provide further information',
        templateReply: 'If the BD staff has not been identified/TBC:',
        templateReplySubpoints: [
          'Roles and responsibilities for the position',
          'Job description for hire, including the salary and currency',
        ],
      },
      {
        key: 'activity_overseasMarketingPresence_BDStaffNote',
        label: 'Note on OMP BD Staff',
        templateReply:
          'Please note that the BD staff employee must meet the following criteria:',
        templateReplySubpoints: [
          'They are on the company payroll and receive a monthly payslip, with CPF contribution if SG/SPR',
          'They are a permanent staff of the company, with no contract end date specified in the employee agreement',
          'They are expected to be stationed in the overseas market throughout the project duration',
        ],
      },
      {
        key: 'activity_overseasMarketingPresence_projectDeliverables',
        label: 'Note on OMP project deliverables',
        templateReply:
          'Please note that you are required to provide evidence of the project deliverables specified in LOF, at claim stage for claims to be disbursed. The expected deliverables are:',
        templateReplySubpoints: [
          "A summary report validating completed marketing/BD activities and the project's outcomes",
          'Evidence of hiring and posting the permanent employee in the market',
          'Any additional requirements outlined in the LOF',
        ],
      },
    ],
  },
  {
    category: ReworkTemplateCategories.activity,
    subCategory: ActivityType.inMarketBusinessDevelopment,
    items: [
      {
        key: 'activity_inMarketBusinessDevelopment_confirmKPI',
        label: 'Confirm In-market BD KPI',
        templateReply: 'Can you confirm the project KPIs for the vendors?',
        templateReplySubpoints: [
          'Scope of work and deliverables from the vendor',
          'Number of leads to be generated by the vendor',
        ],
      },
      {
        key: 'activity_inMarketBusinessDevelopment_projectDeliverables',
        label: 'Note on In-market BD project deliverables',
        templateReply:
          'Do take note that you are required to provide evidence of the project deliverables mentioned in the vendor quotation at claim stage. The expected deliverables are:',
        templateReplySubpoints: [
          'Regular progress reports on BD employee activities and outcome',
          'Market report outlining the business strategy and generated leads with outcomes',
          "List of potential company/lead contacts sourced by the vendor according to the applicant company's criteria",
        ],
      },
    ],
  },
  {
    category: ReworkTemplateCategories.activity,
    subCategory: ActivityType.tradeFairs,
    items: [
      {
        key: 'activity_tradeFairs_supportingDocuments',
        label: 'Require Trade Fair supporting documents',
        templateReply:
          'Could you provide us with the following supporting documents:',
        templateReplySubpoints: [
          'Marketing materials and information for the event',
          'Benefits for attending this event',
          "Applicant's services / products that will be showcased during the exhibition part in the trade show",
        ],
      },
      {
        key: 'activity_tradeFairs_revisedQuotation',
        label: 'Request Trade Fair revised quotation',
        templateReply:
          'Kindly request for a revised quotation which include booth space and cost from the vendor. Do note that it must be addressed to the registered company name as per company’s ACRA Bizfile.',
      },
      {
        key: 'activity_tradeFairs_projectDeliverables',
        label: 'Note on Trade Fairs project deliverables',
        templateReply:
          'Please note that you are required to provide evidence of the project deliverables specified in LOF, at claim stage for claims to be disbursed. The expected deliverables are:',
        templateReplySubpoints: [
          'Event writeup, accompanied by corresponding materials, such as exhibitor listing',
          "Photographs of booth setup, and publicity materials that reflect the company's name ",
        ],
      },
    ],
  },
  {
    category: ReworkTemplateCategories.activity,
    subCategory: ActivityType.identificationOfPotentialPartners,
    items: [
      {
        key: 'activity_identificationOfPotentialPartners_revisedQuotation',
        label:
          'Request Identification of Potential Overseas Partners revised quotation',
        templateReply:
          'Kindly request for a revised quotation and  include detailed breakdown of individual cost from the vendor. Do note that it must be addressed to the registered company name as per company’s ACRA Bizfile. In addition, please confirm the following:',
        templateReplySubpoints: [
          'Number of potential partners to be generated by the vendor',
          'Number of meetings committed by the vendor',
          'Do provide us a revised quotation from your vendor with the above',
        ],
      },
      {
        key: 'activity_identificationOfPotentialPartners_lofDeliverables',
        label:
          'Expected deliverables for Identification of Potential Overseas Partners',
        templateReply:
          'Please note that you are required to provide evidence of the project deliverables specified in LOF, at claim stage for claims to be disbursed. The expected deliverables are:',
        templateReplySubpoints: [
          "List of potential company/lead contacts sourced by the vendor based on the applicant company's criteria, and detailed information on the business profiles of the selected companies for meetings",
          "The final list of companies shortlisted by the applicant company with arranged meetings and documentation on the scheduled business meetings, including company details, representatives' information, meeting details, minutes, venues, and photographs",
        ],
      },
    ],
  },

  {
    category: ReworkTemplateCategories.activity,
    subCategory: ActivityType.marketEntry,
    items: [
      {
        key: 'activity_marketEntry_revisedQuotation',
        label: 'Request Market Entry revised quotation',
        templateReply:
          'Kindly request for a revised quotation and  include detailed breakdown of individual cost from the vendor. Do note that it must be addressed to the registered company name as per company’s ACRA Bizfile',
      },
      {
        key: 'activity_marketEntry_trademark',
        label: 'Confirm on trademark',
        templateReply: 'Kindly confirm with your vendor on:',
        templateReplySubpoints: [
          'The number of trademarks',
          'Classes',
          'Countries to be registered',
          'Visual of the logo/word mark',
        ],
      },
      {
        key: 'activity_marketEntry_incorporationOfOffice',
        label: 'Confirm on incorporation of office',
        templateReply:
          'Please acknowledge that the foreign entity should be registered with the applicant company as the corporate shareholder.',
      },
      {
        key: 'activity_marketEntry_JVFranchiseAgreement',
        label: 'Confirm on JV / Franchise Agreement',
        templateReply:
          'Please confirm that this is to draft the first set of xx agreement as support is limited to only the first set of such agreement.',
      },
      {
        key: 'activity_marketEntry_noteEnglishDocuments',
        label: 'Note to submit english documents',
        templateReply:
          'Do take note that you are expected to submit a copy of the documents in English during the claim stage.',
      },
      {
        key: 'activity_marketEntry_projectDeliverables',
        label: 'Note on Market Entry project deliverables',
        templateReply:
          'Please note that you are required to provide evidence of the project deliverables specified in LOF, at claim stage for claims to be disbursed. The expected deliverables are:',
        templateReplySubpoints: [
          'Relevant filing documents submitted to authorities',
          'Incorporation certificate, permits, licenses obtained',
          'Copies of franchise/JV agreements',
          'Report on assessment of financial and recommendation',
        ],
      },
    ],
  },
  {
    category: ReworkTemplateCategories.activity,
    subCategory: ActivityType.marketingAndPRActivities,
    items: [
      {
        key: 'activity_marketingAndPRActivities_revisedQuotation',
        label: 'Request Overseas Marketing and PR Activities revised quotation',
        templateReply:
          'Kindly request for a revised quotation and  include detailed breakdown of individual cost from the vendor. Do note that it must be addressed to the registered company name as per company’s ACRA Bizfile.',
      },
    ],
  },
]
