import { Text, TextProps } from '@chakra-ui/react'
import { Input } from '@opengovsg/design-system-react'
import { PropsWithChildren } from 'react'

type EditableTextProps = {
  isEditing: boolean
  value: string
  onInputChange: (value: string) => void
  placeholder?: string
} & TextProps

export const EditableText = ({
  isEditing,
  value,
  onInputChange,
  placeholder,
  children,
  ...textProps
}: PropsWithChildren<EditableTextProps>) => {
  if (isEditing) {
    return (
      <Input
        size="xs"
        value={value}
        onChange={(e) => {
          onInputChange(e.target.value)
        }}
        placeholder={placeholder}
      />
    )
  }

  if (children) {
    return <>{children}</>
  }

  return (
    <Text textAlign={'start'} {...textProps}>
      {value}
    </Text>
  )
}
