/**
 * Check if a string[][] is empty.
 * A string[][] is considered empty if:
 *  - Every inner array is empty, or
 *  - Every item in am inner array is an empty string.

 * E.g. [[], []] -> true
 *      [[''], ['']] -> true
 *      [['', ''], []] -> true
 *      [['', 'a'], ['']] -> false
 */
export const isEmpty2DArray = (arr: string[][]): boolean => {
  return arr.every(
    (innerArr) =>
      innerArr.length === 0 || innerArr.every((item) => item === ''),
  )
}

/**
 * Update an array at a specific index with a new value.
 * @param array Array to update
 * @param index Index to update
 * @param newValue New value to replace the old value at the index
 * @returns A new array with the value at the index replaced with the new value
 */
export const updateArrayAtIndex = <T>(
  array: T[],
  index: number,
  newValue: T,
): T[] => {
  const updatedArray = [...array]
  updatedArray[index] = newValue

  return updatedArray
}

/**
 * Clean deliverables, which are usually delimited by newlines, stripping leading / trailing spaces
 * @param deliverables Input array of deliverable string
 * @returns A cleaned array of deliverables
 */
export const cleanDeliverables = (deliverables: string): string[] => {
  return deliverables
    .split('\n')
    .map((line) => line.trim().replace(/^-\s*/, '')) // Remove hyphens and any spaces immediately at the start of the sentence, where hyphen may be used to denote bullet points
    .filter((line) => line !== '')
}
