import { Card, CardBody, Divider, HStack, Text, VStack } from '@chakra-ui/react'
import { useFormContext } from 'react-hook-form'

import { HighlightWords } from '~components/HighlightWords'
import { QualifyingCostCalculation } from '~features/zendesk-home/tabs/Evaluation/CostBreakdown/QualifyingCostCalculation'
import {
  EvaluateFormInputs,
  POEvaluationCostBreakdownThirdPartyVendor,
  ThirdPartyVendor,
} from '~shared/types/application.dto'

import { AddCardButton } from './Edit/AddCardButton'
import { DeleteCardButton } from './Edit/DeleteCardButton'
import { EditableNumberInput } from './Edit/EditableNumberInput'
import { EditableSingleSelect } from './Edit/EditableSingleSelect'
import { EditableText } from './Edit/EditableText'
import { EditPanel } from './Edit/EditPanel'
import { useEdit } from './Edit/useEdit'

export const ThirdPartyVendorCards = ({
  applicationDate,
}: {
  applicationDate: string
}) => {
  const {
    watch,
    setValue,
    formState: { errors },
  } = useFormContext<EvaluateFormInputs>()
  const tpvCost = watch('cost_breakdown_obj.thirdPartyVendors')
  const { isEditing, toggleEdit, onSave, onDiscard } =
    useEdit<EvaluateFormInputs>('cost_breakdown_obj.thirdPartyVendors')
  const errMsg = errors['cost_breakdown_obj']?.thirdPartyVendors?.message

  const isSaveDisabled = () => {
    if (tpvCost.length === 0) {
      // Disallow empty cost items because an MRA application doesn't make sense if you are not claiming for anything.
      return true
    }

    const optionalFields: (keyof POEvaluationCostBreakdownThirdPartyVendor)[] =
      ['applicantRemarks', 'poRemarks']

    // If any of the fields except optionalFields are empty, then return true
    return tpvCost.some((item) => {
      const allFields = Object.keys(item) as Array<keyof typeof item>
      const requiredFields = allFields.filter(
        (field) => !optionalFields.includes(field),
      )
      return requiredFields.some((key) => {
        const value = item[key]
        return (
          value === undefined ||
          value === null ||
          (typeof value === 'string' && value.trim() === '')
        )
      })
    })
  }

  const onDeleteCard = (index: number) => {
    setValue(
      'cost_breakdown_obj.thirdPartyVendors',
      tpvCost.filter((_, i) => i !== index),
    )
  }

  const onAddCard = () => {
    const newTpvCost = [
      ...tpvCost,
      {
        vendorRegisteredIn: '',
        exchangeRate: '',
        vendorNameOrUen: '',
        currency: '',
        amount: '',
        applicantRemarks: '',
        qualifyingAmt: '',
        supportLevel: '50',
        poRemarks: '',
      },
    ]
    setValue('cost_breakdown_obj.thirdPartyVendors', newTpvCost)
  }

  const onEditFieldChange = (
    index: number,
    newValue: Partial<ThirdPartyVendor>,
  ) => {
    const updatedVendors = [...tpvCost]
    updatedVendors[index] = { ...updatedVendors[index], ...newValue }
    setValue('cost_breakdown_obj.thirdPartyVendors', updatedVendors)
  }

  return (
    <>
      {isEditing && (
        <Text
          textColor={'interaction.critical.default'}
          textAlign={'start'}
          mb="4px"
        >
          {`* You are now in edit mode. Please save or discard your changes before submitting the form.`}
        </Text>
      )}
      {errMsg && (
        <Text
          textColor={'interaction.critical.default'}
          textAlign={'start'}
          mb="4px"
        >
          {`* ${errMsg}`}
        </Text>
      )}
      <HStack justifyContent={'space-between'} mb="4px">
        <Text textDecoration={'underline'} textAlign={'start'}>
          Third Party Vendors
        </Text>
        <EditPanel
          isEditing={isEditing}
          isSaveDisabled={isSaveDisabled()}
          saveDisabledTooltipLabel={
            'Please input all required fields in the cost item before saving'
          }
          onEditToggle={toggleEdit}
          onSave={onSave}
          onDiscard={onDiscard}
        />
      </HStack>
      {tpvCost.length === 0 && (
        <Text color="utility.feedback.critical" py="8px">
          You must have at least one cost item
        </Text>
      )}
      {tpvCost.map((row, rowIndex) => {
        const {
          vendorRegisteredIn,
          vendorNameOrUen,
          currency,
          exchangeRate,
          applicantRemarks = '',
          amount,
        } = row
        return (
          <Card
            key={rowIndex}
            mb={'4px'}
            boxShadow={'none'}
            border={'1px solid'}
            borderColor={'gray.200'}
          >
            <CardBody>
              <>
                <VStack alignItems={'start'} spacing={1}>
                  <HStack justifyContent={'space-between'} w={'100%'}>
                    <EditableSingleSelect
                      name={'thirdPartyVendors_vendorRegisteredIn'}
                      items={['Singapore', 'Overseas']}
                      isEditing={isEditing}
                      value={vendorRegisteredIn}
                      placeholder={'Vendor registered in'}
                      onInputChange={(newVal) => {
                        onEditFieldChange(rowIndex, {
                          vendorRegisteredIn: newVal,
                        })
                      }}
                    >
                      <HighlightWords
                        words={['Singapore', 'Overseas']}
                        chosenWord={vendorRegisteredIn}
                      />
                    </EditableSingleSelect>
                    <HStack>
                      {tpvCost.length > 1 && (
                        <HStack>
                          <Text textStyle={'subhead-3'}>
                            {rowIndex + 1} of {tpvCost.length}
                          </Text>
                        </HStack>
                      )}
                      {isEditing && (
                        <DeleteCardButton
                          tooltipLabel={'Delete this cost item'}
                          onDelete={() => {
                            onDeleteCard(rowIndex)
                          }}
                        />
                      )}
                    </HStack>
                  </HStack>
                  <EditableText
                    isEditing={isEditing}
                    value={vendorNameOrUen}
                    onInputChange={(newVal) => {
                      onEditFieldChange(rowIndex, {
                        vendorNameOrUen: newVal,
                      })
                    }}
                    placeholder={'Vendor name or UEN'}
                  />
                  <EditableText
                    isEditing={isEditing}
                    value={applicantRemarks}
                    onInputChange={(newVal) => {
                      onEditFieldChange(rowIndex, {
                        applicantRemarks: newVal,
                      })
                    }}
                    placeholder={'Applicant Remarks'}
                    textStyle="body-2"
                    textColor="base.content.medium"
                  />
                  <HStack
                    gap={0}
                    textAlign={'start'}
                    justifyContent={'space-between'}
                    w={'100%'}
                  >
                    <Text textStyle={'subhead-2'} flexShrink={0}>
                      Cost
                    </Text>
                    <EditableNumberInput
                      isEditing={isEditing}
                      value={amount}
                      onInputChange={(newVal) => {
                        onEditFieldChange(rowIndex, {
                          amount: newVal,
                        })
                      }}
                      placeholder={'Cost'}
                      textStyle="body-2"
                    />
                  </HStack>
                </VStack>
                <Divider my="8px" />
                <QualifyingCostCalculation
                  formFieldName={'cost_breakdown_obj.thirdPartyVendors'}
                  exchangeRate={exchangeRate}
                  currency={currency}
                  rowIndex={rowIndex}
                  isEditing={isEditing}
                  onEditFieldChange={onEditFieldChange}
                  applicationDate={applicationDate}
                />
              </>
            </CardBody>
          </Card>
        )
      })}
      {isEditing && (
        <AddCardButton
          onClick={onAddCard}
          tooltipLabel={'Add a new cost item'}
          my={'8px'}
        />
      )}
    </>
  )
}
