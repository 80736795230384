import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  FormControl,
  HStack,
  Input,
  Text,
  VStack,
} from '@chakra-ui/react'
import {
  Button,
  FormErrorMessage,
  FormLabel,
  TagInput,
} from '@opengovsg/design-system-react'
import { UseMutationResult } from '@tanstack/react-query'
import { useState } from 'react'
import { Controller, useFormContext } from 'react-hook-form'

import { FdpFormInputs } from '~features/zendesk-home/tabs/Fdp/FdpTab'
import { FdpInsightsResp } from '~shared/types/fdp.dto'
import { isUenValid } from '~shared/utils/uenValidation'

export type FdpInsightsQueryProps = {
  getFdpInsightsMutation: UseMutationResult<
    FdpInsightsResp,
    unknown,
    FdpFormInputs,
    unknown
  >
}

export const FdpInsightsQuery = ({
  getFdpInsightsMutation,
}: FdpInsightsQueryProps) => {
  const [accordionIndices, setAccordionIndices] = useState([0])

  const {
    formState: { errors, isValid },
    getValues,
    control,
    handleSubmit,
  } = useFormContext<FdpFormInputs>()

  const handleToggle = (index: number) => {
    if (accordionIndices.includes(index)) {
      setAccordionIndices([])
    } else {
      setAccordionIndices([index])
    }
  }

  const onSubmit = (data: FdpFormInputs) => {
    setAccordionIndices([])
    return getFdpInsightsMutation.mutate(data)
  }
  const errorMsg = errors.targetUenList?.message
  return (
    <Box w={'100%'}>
      <form onSubmit={(e) => void handleSubmit(onSubmit)(e)}>
        <Accordion allowToggle index={accordionIndices} onChange={handleToggle}>
          <AccordionItem>
            <Box w={'100%'}>
              <AccordionButton>
                <HStack
                  textAlign={'left'}
                  justifyContent={'space-between'}
                  w={'100%'}
                >
                  <Text fontSize={'14px'}>Query FDP Insights</Text>
                  <AccordionIcon />
                </HStack>
              </AccordionButton>
              <AccordionPanel>
                <VStack w={'100%'}>
                  <HStack w={'100%'}>
                    <FormLabel
                      whiteSpace={'nowrap'}
                      fontSize={'12px'}
                      isRequired={true}
                      minW={'120px'}
                    >
                      Applicant UEN
                    </FormLabel>
                    <Controller
                      name={'applicantUen'}
                      control={control}
                      render={({ field }) => {
                        return (
                          <Input
                            w={'100%'}
                            {...field}
                            disabled={true}
                            size={'xs'}
                          />
                        )
                      }}
                    />
                  </HStack>
                  <HStack w={'100%'}>
                    <FormLabel
                      whiteSpace={'nowrap'}
                      isRequired={true}
                      fontSize={'12px'}
                      minW={'120px'}
                    >
                      Submitter IC
                    </FormLabel>
                    <Controller
                      name={'applicantNric'}
                      control={control}
                      render={({ field }) => {
                        return (
                          <Input
                            w={'100%'}
                            {...field}
                            disabled={true}
                            size={'xs'}
                          />
                        )
                      }}
                    />
                  </HStack>
                  <FormControl isInvalid={!!errorMsg}>
                    <HStack w={'100%'}>
                      <FormLabel
                        minW={'120px'}
                        fontSize={'12px'}
                        isRequired={true}
                        whiteSpace={'nowrap'}
                        tooltipText={'Enter Vendor UENs, separated by space'}
                      >
                        Target vendor(s)
                      </FormLabel>
                      <Controller
                        name={'targetUenList'}
                        control={control}
                        rules={{
                          validate: {
                            isValidUen: (value) =>
                              value.every(isUenValid) ||
                              'Some UENs are invalid',
                            isNotApplicantUen: (value) =>
                              !value.includes(getValues('applicantUen')) ||
                              'Applicant UEN is not valid as a target',
                          },
                        }}
                        render={({ field }) => {
                          return (
                            <TagInput
                              {...field}
                              tagValidation={(v) =>
                                isUenValid(v) && getValues('applicantUen') !== v
                              }
                              size={'sm'}
                              onChange={field.onChange}
                              style={{ width: '100%' }}
                            />
                          )
                        }}
                      />
                    </HStack>

                    <FormErrorMessage fontSize={'12px'}>
                      {errorMsg}
                    </FormErrorMessage>
                  </FormControl>

                  <Button
                    type={'submit'}
                    size={'xs'}
                    isDisabled={getFdpInsightsMutation.isLoading || !isValid}
                  >
                    Generate Insights
                  </Button>
                </VStack>
              </AccordionPanel>
            </Box>
          </AccordionItem>
        </Accordion>
      </form>
    </Box>
  )
}
