import { Flex, InputGroup, InputLeftAddon } from '@chakra-ui/react'
import { NumberInput, NumberInputProps } from '@opengovsg/design-system-react'
import { forwardRef } from 'react'

type CostInputProps = NumberInputProps & {
  currencyCode: string
}

export const CostInput = forwardRef((props: CostInputProps, ref) => {
  return (
    <Flex>
      <InputGroup size={'xs'}>
        <InputLeftAddon pointerEvents={'none'}>
          {props.currencyCode}
        </InputLeftAddon>
        <NumberInput ref={ref} {...props} showSteppers={false} />
      </InputGroup>
    </Flex>
  )
})
CostInput.displayName = 'CostInput'
