import {
  Box,
  Divider,
  FormControl,
  HStack,
  Text,
  VStack,
} from '@chakra-ui/react'
import {
  Checkbox,
  FormErrorMessage,
  FormLabel,
  Infobox,
  Tooltip,
} from '@opengovsg/design-system-react'
import { Decimal } from 'decimal.js'
import { Controller, useFormContext } from 'react-hook-form'
import { FaQuestionCircle } from 'react-icons/fa'

import {
  OBD_GRANT_CAP_LIMIT,
  OMP_GRANT_CAP_LIMIT,
  OMS_GRANT_CAP_LIMIT,
  REQUIRED_MSG,
} from '~features/zendesk-home/tabs/Evaluation/constant'
import { CostInput } from '~features/zendesk-home/tabs/Evaluation/CostBreakdown/CostInput'
import { ActivityType } from '~shared/constants/application'
import { AUDITOR_FEE } from '~shared/constants/mra'
import {
  EvaluateFormInputs,
  POEvaluationCostBreakdownSalaries,
  POEvaluationCostBreakdownThirdPartyVendors,
  POEvaluationOfficeSpaceRentals,
} from '~shared/types/application.dto'
import { computeCostItems, computeTotalGrantAmt } from '~shared/utils/number'
import {
  formatNumberAsSingaporeCurrency,
  formatNumberOrDefault,
} from '~shared/utils/string'

import { OfficeSpaceRentalCards } from './OfficeSpaceRentalCards'
import { SalaryCards } from './SalaryCards'
import { ThirdPartyVendorCards } from './ThirdPartyVendorCards'

type CostBreakdownProps = {
  activityType: ActivityType
  applicationDate: string
}

export const CostBreakdownSection = ({
  activityType,
  applicationDate,
}: CostBreakdownProps) => {
  const {
    watch,
    control,
    formState: { errors },
  } = useFormContext<EvaluateFormInputs>()
  const tpvCost = watch('cost_breakdown_obj.thirdPartyVendors')
  const salaryCost = watch('cost_breakdown_obj.salaries')
  const osrCost = watch('cost_breakdown_obj.officeSpaceRentals')
  const finalGrantAmount = watch('costSummary_finalGrantAmount')
  const calculateTotalGrantAmount = (costBreakdownObj: {
    thirdPartyVendors: POEvaluationCostBreakdownThirdPartyVendors
    salaries: POEvaluationCostBreakdownSalaries
    officeSpaceRentals: POEvaluationOfficeSpaceRentals
  }): number => {
    const costItems = computeCostItems(costBreakdownObj)
    const totalGrantAmount = computeTotalGrantAmt(costItems)
    return isNaN(totalGrantAmount) ? 0 : totalGrantAmount
  }
  const totalGrantAmount = calculateTotalGrantAmount({
    thirdPartyVendors: tpvCost,
    salaries: salaryCost,
    officeSpaceRentals: osrCost,
  })

  const getCurrentGrantCapLimit = () => {
    switch (activityType) {
      case ActivityType.ftaAndTradeComplianceConsultancy:
      case ActivityType.marketEntry:
        return OMS_GRANT_CAP_LIMIT
      case ActivityType.tradeFairs:
      case ActivityType.marketingAndPRActivities:
        return OMP_GRANT_CAP_LIMIT
      case ActivityType.identificationOfPotentialPartners:
      case ActivityType.inMarketBusinessDevelopment:
      case ActivityType.overseasMarketingPresence:
        return OBD_GRANT_CAP_LIMIT
    }
  }

  return (
    <Box>
      {activityType !== ActivityType.overseasMarketingPresence && (
        <ThirdPartyVendorCards applicationDate={applicationDate} />
      )}
      {activityType === ActivityType.overseasMarketingPresence && (
        <SalaryCards applicationDate={applicationDate} />
      )}
      {activityType === ActivityType.overseasMarketingPresence && (
        <OfficeSpaceRentalCards applicationDate={applicationDate} />
      )}
      <VStack alignItems={'start'} my={'8px'}>
        <Text textStyle={'h6'} mt="12px">
          Cost Summary
        </Text>
        {totalGrantAmount + AUDITOR_FEE > getCurrentGrantCapLimit() && (
          <Infobox
            variant={'warning'}
            size={'sm'}
            textAlign={'start'}
            w={'100%'}
          >
            Total Grant Amount has exceeded the Grant Cap Limit of SGD{' '}
            {formatNumberOrDefault(getCurrentGrantCapLimit().toString())}
          </Infobox>
        )}
        <HStack justifyContent={'space-between'} w={'100%'}>
          <Text textStyle={'subhead-2'}>Proposed Grant Amount</Text>
          <Text textStyle={'subhead-2'}>
            {formatNumberAsSingaporeCurrency(totalGrantAmount)}
          </Text>
        </HStack>
        <HStack justifyContent={'space-between'} w={'100%'}>
          <Text textStyle={'subhead-2'}>Audit Fee</Text>
          <Text textStyle={'subhead-2'}>
            {formatNumberAsSingaporeCurrency(AUDITOR_FEE)}
          </Text>
        </HStack>
        <HStack justifyContent={'space-between'} w={'100%'}>
          <Text textStyle={'subhead-2'} fontWeight={'700'}>
            Total Grant Amount
          </Text>
          <VStack>
            <Divider borderColor={'black'} />
            <Text
              textStyle={'subhead-2'}
              fontWeight={'700'}
              color={
                totalGrantAmount + AUDITOR_FEE > getCurrentGrantCapLimit()
                  ? 'yellow.500'
                  : ''
              }
            >
              {formatNumberAsSingaporeCurrency(totalGrantAmount + AUDITOR_FEE)}
            </Text>
          </VStack>
        </HStack>

        <FormControl
          isInvalid={!!errors['costSummary_finalGrantAmount']?.message}
        >
          <HStack justifyContent={'space-between'} w={'100%'} mt={'4px'}>
            <HStack>
              <Text
                textStyle={'subhead-2'}
                textAlign={'start'}
                fontWeight={'700'}
              >
                Final Grant Amount
              </Text>
              <Tooltip
                label={
                  'Input the final grant amount, considering the grant cap and cost items.'
                }
              >
                <FaQuestionCircle fontSize={'12px'} />
              </Tooltip>
            </HStack>

            <Controller
              control={control}
              name={'costSummary_finalGrantAmount'}
              rules={{
                validate: (value) => {
                  if (!value) {
                    return REQUIRED_MSG
                  }
                  const decimalValue = new Decimal(value)
                  const totalAmtWithAudit = new Decimal(totalGrantAmount).plus(
                    AUDITOR_FEE,
                  )

                  if (
                    decimalValue.gt(totalAmtWithAudit) &&
                    decimalValue.lte(getCurrentGrantCapLimit())
                  ) {
                    return 'Final Grant Amount has exceeded the Total Grant Amount'
                  }
                },
              }}
              render={({ field }) => (
                <CostInput
                  {...field}
                  w={'120px'}
                  currencyCode={'SGD'}
                  isInvalid={
                    !field.value ||
                    (Number(field.value) > totalGrantAmount + AUDITOR_FEE &&
                      Number(field.value) <= getCurrentGrantCapLimit())
                  }
                />
              )}
            />
          </HStack>
          <FormErrorMessage>
            {errors['costSummary_finalGrantAmount']?.message}
          </FormErrorMessage>
        </FormControl>
        {Number(finalGrantAmount) > getCurrentGrantCapLimit() && (
          <FormControl
            isInvalid={!!errors['costSummary_bypassGrantCap']?.message}
            bgColor={'interaction.warning-subtle.default'}
            p={'0.625rem'}
          >
            <FormLabel
              isRequired
              size={'sm'}
              tooltipText={`Deviational Approval is required to submit this evaluation as the final proposed grant amount exceeds the grant cap of ${formatNumberAsSingaporeCurrency(
                getCurrentGrantCapLimit(),
              )}`}
            >
              Deviational Approval
            </FormLabel>
            <FormErrorMessage>
              {errors['costSummary_bypassGrantCap']?.message}
            </FormErrorMessage>
            <VStack>
              <Controller
                name={'costSummary_bypassGrantCap'}
                rules={{
                  required:
                    Number(finalGrantAmount) > getCurrentGrantCapLimit()
                      ? REQUIRED_MSG
                      : false,
                }}
                render={({ field }) => (
                  <Checkbox
                    {...field}
                    onChange={field.onChange}
                    defaultChecked={!!field.value}
                    size={'xs'}
                    borderColor={'utility.feedback.critical'}
                    borderWidth={
                      errors['costSummary_bypassGrantCap']?.message
                        ? 1
                        : undefined
                    }
                  >
                    <Text textAlign={'start'}>
                      I have obtained deviational approval to bypass the grant
                      cap.
                    </Text>
                  </Checkbox>
                )}
              />
            </VStack>
          </FormControl>
        )}
      </VStack>
    </Box>
  )
}
