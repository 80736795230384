import {
  Flex,
  SkeletonText,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
} from '@chakra-ui/react'
import { useQuery } from '@tanstack/react-query'

import { QUERY_KEYS } from '~constants/queryKeys'
import { EvaluationTab } from '~features/zendesk-home/tabs/Evaluation'
import { FdpTab } from '~features/zendesk-home/tabs/Fdp'
import { useZendeskClient } from '~lib/zendeskClient'
import {
  FetchApplicationInfoResp,
  FetchEvaluationResp,
} from '~shared/types/application.dto'

import { ReworkTab } from '../tabs/Rework/ReworkTab'

export const ZendeskHomePage = (): JSX.Element => {
  const { isLoadedWithinZendeskApp, zendeskRequest, ticketId } =
    useZendeskClient()

  const {
    data: applicationInfo,
    isFetching: isFetchingApplicationInfo,
    isError,
  } = useQuery({
    queryKey: [QUERY_KEYS.FETCH_APPLICATION_INFO],
    queryFn: async () => {
      if (!ticketId) {
        return
      }
      return zendeskRequest<FetchApplicationInfoResp>(
        `/application/${ticketId}/fetch-application-info`,
        'GET',
      )
    },
    refetchOnWindowFocus: false,
    enabled: !!ticketId,
  })

  const {
    data: submittedEvaluation,
    isLoading: isFetchingSubmittedEvaluationForFirstTIme,
  } = useQuery({
    queryKey: [QUERY_KEYS.FETCH_EVALUATION],
    queryFn: async () => {
      if (!ticketId) {
        return null
      }
      const resp = await zendeskRequest<FetchEvaluationResp>(
        `/application/${ticketId}/evaluation`,
        'GET',
      )
      return resp.evaluationForm || null
    },
    refetchOnWindowFocus: false,
    enabled: !!ticketId,
  })

  if (!isLoadedWithinZendeskApp) {
    return (
      <Text align={'center'} py="40px">
        This application can only be used within Zendesk custom app.
      </Text>
    )
  }

  if (isFetchingApplicationInfo || isFetchingSubmittedEvaluationForFirstTIme) {
    return (
      <SkeletonText
        w={'50%'}
        my="40px"
        alignSelf={'center'}
        noOfLines={4}
        spacing="4"
        skeletonHeight="6"
      />
    )
  }
  if (!applicationInfo || isError) {
    return (
      <Text align={'center'} py="40px">
        Unable to get application data. Please try refreshing the page.
      </Text>
    )
  }

  return (
    <Flex textAlign="center" justifyContent="center" flexDir="column">
      <Text textStyle={'h4'} bg={'white'} color={'brand.primary.500'}>
        {applicationInfo.companyName}
      </Text>
      <Tabs>
        <TabList
          flexDirection={{ base: 'column', sm: 'row' }}
          py={{ base: '12px', sm: '0px' }}
          gap={{ base: '0', sm: '1em' }}
          backgroundColor="white"
          justifyContent="space-around"
          alignItems="center"
        >
          <Tab>Tools</Tab>
          <Tab>Rework</Tab>
          <Tab>Evaluation</Tab>
          {/* <Tab>Letter of Offer</Tab> */}
        </TabList>
        <TabPanels px="24px" pt="24px">
          <TabPanel>
            <FdpTab applicationInfo={applicationInfo} />
          </TabPanel>
          <TabPanel>
            <ReworkTab applicationInfo={applicationInfo} />
          </TabPanel>
          <TabPanel>
            <EvaluationTab
              applicationInfo={applicationInfo}
              submittedEvaluation={submittedEvaluation || undefined}
            />
          </TabPanel>
          {/* <TabPanel>
            <LetterOfOfferTab
              applicationInfo={applicationInfo}
              submittedEvaluation={submittedEvaluation || undefined}
            />
          </TabPanel> */}
        </TabPanels>
      </Tabs>
    </Flex>
  )
}
