import dayjs from 'dayjs'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import minMax from 'dayjs/plugin/minMax'
import Decimal from 'decimal.js'

dayjs.extend(customParseFormat)
dayjs.extend(minMax)

export const isValidDate = (
  dateString: string,
  format = 'DD/MM/YYYY',
): boolean => {
  if (dateString === '') return true
  const parsedDate = dayjs(dateString, format, true)
  return parsedDate.isValid()
}

/**
 * Parse a date string into a Date object.
 * If the date string is not valid, return todays date
 * @param dateString a date string in the format DD/MM/YYYY
 * @param format a Date object, or null if the date string is not valid
 */
export const parseDate = (dateString: string, format = 'DD/MM/YYYY') => {
  const parsedDate = dayjs(dateString, format, true)

  if (parsedDate.isValid()) {
    return parsedDate
  }

  return dayjs()
}

export const getTodayDateString = () => {
  return dayjs().format('DD MMM YYYY')
}

/**
 * Formats a date object into a string in the format DD/MM/YYYY
 */
export const formatDate = (date: Date): string => {
  return dayjs(date).format('DD/MM/YYYY')
}

/**
 * Transform a date string from one format to another
 * @param dateString The date string
 * @param options from: the format of the input date string, to: the format of the output date string.
 */
export const formatDateString = (
  dateString: string,
  options: {
    from: string
    to: string
  },
): string => {
  return dayjs(dateString, options.from).format(options.to)
}

/**
 * Takes in a projectEndDate of the format `DD MMM YYYY`, and return a datestring in the format `DD MMM YYYY`
 * that is the max of the 2:
 *    1. projectEndDate + 3 months
 *    2. today + 3 months
 * @param projectEndDate a datestring in the format `DD MMM YYYY`
 * @returns a datestring in the format `DD MMM YYYY`
 */
export const getClaimDueDate = (projectEndDate: string) => {
  const projectEndDatePlus3Months = dayjs(projectEndDate, 'DD MMM YYYY').add(
    3,
    'month',
  )
  const todayPlus3Months = dayjs().add(3, 'month')
  const maxDate = dayjs.max(projectEndDatePlus3Months, todayPlus3Months)
  if (maxDate) {
    return maxDate.format('DD MMM YYYY')
  }

  return projectEndDate
}

/**
 * Letter of offer due date is 14 days from today
 * @returns A date string in the format `DD MMM YYYY`
 */
export const getLetterOfOfferDueDate = () => {
  return dayjs().add(14, 'day').format('DD MMM YYYY')
}

/**
 * Takes in a startDate and endDate, and returns whether the duration between the 2 dates is within 1 year
 * @param startDate A datestring
 * @param endDate A datestring
 * @returns true if the duration between the 2 dates is within 1 year, false otherwise
 */
export function isDurationWithinOneYear(
  startDateStr: string,
  endDateStr: string,
) {
  const startDate = new Date(startDateStr)
  const endDate = new Date(endDateStr)

  if (endDate < startDate) {
    return false
  }

  const oneYearAfterStart = new Date(startDate)
  oneYearAfterStart.setFullYear(startDate.getFullYear() + 1)

  return endDate < oneYearAfterStart
}

/**
 * Checks if the end date is the same as or after the start date.
 * @param startDateStr The start date string.
 * @param endDateStr The end date string.
 * @param format The format of the date strings.
 * @returns Whether the end date is the same as or after the start date.
 */
export const isEndDateEqualOrAfterStartDate = (
  startDateStr: string,
  endDateStr: string,
  format = 'DD MMM YYYY',
): boolean => {
  const startDate = dayjs(startDateStr, format)
  const endDate = dayjs(endDateStr, format)
  return endDate.isAfter(startDate) || endDate.isSame(startDate)
}

/**
 * Check if the date is within the specified number of months from today.
 * @param dateStr The date string to check against.
 * @param months Number of months from today.
 * @param format The format of the date strings.
 * @returns True if the date is within the specified number of months from today.
 */
export const isDateWithinMonthsFromToday = (
  dateStr: string,
  months: number,
  format = 'DD MMM YYYY',
) => {
  const date = dayjs(dateStr, format)
  const today = dayjs()
  return date.isBefore(today.add(months, 'months'))
}

/**
 * Helper function to if the first duration is fully encapsulated (<=) the second duration
 * This function converts two numbers into decimal to ensure no floating point discrepancies
 * @param firstDuration a number (months)
 * @param secondDuration a number (months)
 * @returns true if firstDuration is <= secondDuration
 */
export function isDurationWithinAnother(
  firstDuration: number,
  secondDuration: number,
) {
  if (isNaN(firstDuration) || isNaN(secondDuration)) {
    return false
  }
  const firstDurationDec = new Decimal(firstDuration)
  const secondDurationDec = new Decimal(secondDuration)

  return firstDurationDec.lessThanOrEqualTo(secondDurationDec)
}
