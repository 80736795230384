import { ActivityType } from '~shared/constants/application'

export const DELIVERABLES: {
  [key in ActivityType]: string[]
} = {
  [ActivityType.marketingAndPRActivities]: [
    'Event write-up attached with corresponding materials such as photographs and publicity materials, including URLs to social media links and screenshot for hard-copy publications',
    'Overall roadmap/ communication plan of activities that have been completed',
    'Summary of the project',
    'Company research',
    'Market research',
    'List of marketing articles developed',
    'Results of the campaign',
    'List of influencers/ KOIs engaged',
    'URLs to all the posts',
    'Details of press release articles created',
  ],
  [ActivityType.tradeFairs]: [
    'Publicity materials',
    "Photos of booth which contains Applicant's company name(xxx pte ltd).",
    "Should the booth contain applicant's product name, you are required to provide the TradeMark as proof of product/service ownership",
    'Photos (or screenshots if virtual) of event attended',
    "Exhibitors' listings",
    'Marketing Materials developed (if any). These materials which should contain the event name, solely be used for the event. Generic materials cannot be supported.',
    'Virtual business meetings sessions(If applicable)',
  ],
  [ActivityType.identificationOfPotentialPartners]: [
    'List of up to 50 potential partners and their profiles for selection',
    'Documentation of up to 15 scheduled business meetings with selected partners',
  ],
  [ActivityType.overseasMarketingPresence]: [
    'Summary Report proofing marketing/BD activities completed and outcome of project',
    'Employee agreement which states the Employee belongs to the Employer company. This must be a permanent contract (no contract end date in the agreement)',
    "Provide tenancy agreement or other document(s) to demonstrate BD staff's proof of stay in the market",
    'This employee must be a Singapore/SPR. If the employee is a foreigner, a change request must be submitted to up date the employee details and support level(Reduction of 20%)',
  ],
  [ActivityType.inMarketBusinessDevelopment]: [
    'Monthly report on BD Employee progress and activities completed',
    'Market report with business strategy developed',
    'List of x leads generated with business outcome',
  ],
  [ActivityType.marketEntry]: [
    'Incorporation of entity with at least 30% shareholder owned by applicant company (Not an individual)',
    'Opening of bank account',
    'Business license obtained',
    'Documentation of tax structure',
    'Intellectual Property registration owned by applicant company (Not an individual)',
    'TradeMark registration owned by applicant company (Not an individual)',
    'Drafting of Franchising agreement',
  ],
  [ActivityType.ftaAndTradeComplianceConsultancy]: [],
}
