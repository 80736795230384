import {
  NumberInput,
  NumberInputField,
  NumberInputProps,
  Text,
  TextProps,
} from '@chakra-ui/react'
import { PropsWithChildren } from 'react'

type EditableNumberInputProps = {
  isEditing: boolean
  value: string
  onInputChange: (value: string) => void
  placeholder?: string
  numberInputOpts?: NumberInputProps
} & TextProps

export const EditableNumberInput = ({
  isEditing,
  value,
  onInputChange,
  placeholder,
  children,
  numberInputOpts,
  ...textProps
}: PropsWithChildren<EditableNumberInputProps>) => {
  if (isEditing) {
    return (
      <NumberInput
        size="xs"
        value={value}
        onChange={(newVal) => {
          onInputChange(newVal)
        }}
        {...numberInputOpts}
      >
        <NumberInputField px={'8px'} placeholder={placeholder} />
      </NumberInput>
    )
  }

  if (children) {
    return <>{children}</>
  }

  return (
    <Text textAlign={'start'} {...textProps}>
      {value}
    </Text>
  )
}
