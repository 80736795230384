import { Box, Icon, Wrap, WrapItem } from '@chakra-ui/react'
import { Badge, Textarea, Tooltip } from '@opengovsg/design-system-react'
import { Controller, useFormContext } from 'react-hook-form'
import { BiPlus } from 'react-icons/bi'

import { ActivityType } from '~shared/constants/application'
import { EvaluateFormInputs } from '~shared/types/application.dto'

import { DELIVERABLES } from './constants'

interface DeliverablesSectionProps {
  activityType: string
}

export const Deliverables = ({ activityType }: DeliverablesSectionProps) => {
  const { control, setValue, getValues } = useFormContext<EvaluateFormInputs>()

  const deliverables = DELIVERABLES[activityType as ActivityType]
  const addDeliverables = (d: number) => {
    const currentDeliverables = getValues('deliverables')
    setValue(
      'deliverables',
      currentDeliverables.concat(
        (currentDeliverables.length === 0 ? '- ' : '\n- ') + deliverables[d],
      ),
    )
  }
  return (
    <Box>
      <Wrap spacing={1} justify={'start'} mb={'10px'}>
        {deliverables.map((d, index) => (
          <WrapItem
            key={d}
            cursor={'pointer'}
            onClick={() => {
              addDeliverables(index)
            }}
          >
            <Tooltip label={d}>
              <Badge
                variant={'subtle'}
                _hover={{ bg: 'transparent', color: 'blue.700' }}
                transition={'all 0.5s ease'}
              >
                <Icon as={BiPlus} fontSize={'10px'} />
                {d}
              </Badge>
            </Tooltip>
          </WrapItem>
        ))}
      </Wrap>
      <Controller
        name={'deliverables'}
        control={control}
        render={({ field }) => <Textarea {...field} size={'sm'} />}
      />
    </Box>
  )
}
