import { HStack, Input, Radio, RadioGroup, Stack, Text } from '@chakra-ui/react'
import { Checkbox, DatePicker, Textarea } from '@opengovsg/design-system-react'
import { ControllerRenderProps } from 'react-hook-form'

import { EvaluateFormInputs } from '~shared/types/application.dto'
import { parseDate } from '~shared/utils/date'

import { Question } from './types'

type FormFieldComponentProps = {
  field: ControllerRenderProps<EvaluateFormInputs, keyof EvaluateFormInputs>
  question: Question
  isInvalid?: boolean
}

/**
 * Renders an appropriate component based on the field type.
 */
export const FormFieldComponent = ({
  field,
  question,
  isInvalid = false,
}: FormFieldComponentProps) => {
  const { value, ref, onBlur, onChange } = field
  const { type, customProps } = question

  if (type === 'cost_breakdown' || type === 'deliverables') {
    // For cost section, we handle them separately in CostBreakdownSection
    return null
  }

  if (value !== undefined && typeof value !== 'string') {
    switch (type) {
      case 'checkbox':
        return (
          <Checkbox onChange={field.onChange}>
            {customProps?.checkbox?.text}
          </Checkbox>
        )
      default:
        return null
    }
  }

  // Assert that the value is of type string
  const strField = {
    ...field,
    value,
  }
  switch (type) {
    case 'date':
      return (
        <DatePicker
          ref={ref}
          value={parseDate(value).toDate() || new Date()}
          inputValue={value}
          onBlur={onBlur}
          onInputValueChange={(val) => {
            onChange(val)
          }}
          onChange={(value) => {
            return value ?? new Date()
          }}
          isInvalid={isInvalid}
        />
      )
    case 'true_false':
      return (
        <RadioGroup {...strField}>
          <HStack
            borderColor={'utility.feedback.critical'}
            borderWidth={isInvalid ? 1 : undefined}
          >
            <Radio value="True" isInvalid={isInvalid}>
              True
            </Radio>
            <Radio value="False" isInvalid={isInvalid}>
              False
            </Radio>
          </HStack>
        </RadioGroup>
      )
    case 'custom_radio':
      return (
        <RadioGroup {...strField}>
          <Stack
            direction={customProps?.custom_radio?.isVertical ? 'column' : 'row'}
            borderColor={'utility.feedback.critical'}
            borderWidth={isInvalid ? 1 : undefined}
          >
            {(customProps?.custom_radio?.radioSelections || []).map(
              (selection) => {
                return (
                  <Radio
                    key={selection}
                    value={selection}
                    isInvalid={isInvalid}
                  >
                    <Text textAlign={'start'}>{selection}</Text>
                  </Radio>
                )
              },
            )}
          </Stack>
        </RadioGroup>
      )
    case 'textarea':
      return (
        <Textarea
          {...strField}
          isInvalid={isInvalid}
          {...customProps?.textarea}
        />
      )
    default:
      return <Input {...strField} isInvalid={isInvalid} />
  }
}
