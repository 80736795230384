import {
  Box,
  Card,
  CardBody,
  Divider,
  HStack,
  Text,
  VStack,
} from '@chakra-ui/react'
import { useFormContext } from 'react-hook-form'

import {
  EvaluateFormInputs,
  OfficeSpaceRental,
  POEvaluationOfficeSpaceRental,
} from '~shared/types/application.dto'

import { AddCardButton } from './Edit/AddCardButton'
import { DeleteCardButton } from './Edit/DeleteCardButton'
import { EditableNumberInput } from './Edit/EditableNumberInput'
import { EditableText } from './Edit/EditableText'
import { EditPanel } from './Edit/EditPanel'
import { useEdit } from './Edit/useEdit'
import { QualifyingCostCalculation } from './QualifyingCostCalculation'

export const OfficeSpaceRentalCards = ({
  applicationDate,
}: {
  applicationDate: string
}) => {
  const {
    watch,
    setValue,
    formState: { errors },
  } = useFormContext<EvaluateFormInputs>()
  const osrCost = watch('cost_breakdown_obj.officeSpaceRentals')
  const errMsg = errors['cost_breakdown_obj']?.officeSpaceRentals?.message
  const { isEditing, toggleEdit, onSave, onDiscard } =
    useEdit<EvaluateFormInputs>('cost_breakdown_obj.officeSpaceRentals')

  const isSaveDisabled = () => {
    const optionalFields: (keyof POEvaluationOfficeSpaceRental)[] = [
      'monthlyCost',
      'poRemarks',
    ]

    // If any of the fields except optionalFields are empty, then return true
    return osrCost.some((item) => {
      const allFields = Object.keys(item) as Array<keyof typeof item>
      const requiredFields = allFields.filter(
        (field) => !optionalFields.includes(field),
      )
      return requiredFields.some((key) => {
        const value = item[key]

        return (
          value === undefined ||
          value === null ||
          (typeof value === 'string' && value.trim() === '')
        )
      })
    })
  }

  const onEditFieldChange = (
    index: number,
    newValue: Partial<OfficeSpaceRental>,
  ) => {
    const updatedRentals = [...osrCost]
    updatedRentals[index] = { ...updatedRentals[index], ...newValue }
    setValue('cost_breakdown_obj.officeSpaceRentals', updatedRentals)
  }

  const onAddCard = () => {
    const newOsrCost = [
      ...osrCost,
      {
        exchangeRate: '',
        description: '',
        currency: '',
        monthlyCost: '',
        durationInMonths: '',
        qualifyingAmt: '',
        supportLevel: '0',
        poRemarks: '',
      },
    ]
    setValue('cost_breakdown_obj.officeSpaceRentals', newOsrCost)
  }

  const onDeleteCard = (index: number) => {
    setValue(
      'cost_breakdown_obj.officeSpaceRentals',
      osrCost.filter((_, i) => i !== index),
    )
  }

  return (
    <Box mt="32px">
      {isEditing && (
        <Text
          textColor={'interaction.critical.default'}
          textAlign={'start'}
          mb="4px"
        >
          {`* You are now in edit mode. Please save or discard your changes before submitting the form.`}
        </Text>
      )}
      {errMsg && (
        <Text
          textColor={'interaction.critical.default'}
          textAlign={'start'}
          mb="4px"
        >
          {`* ${errMsg}`}
        </Text>
      )}
      <HStack justifyContent={'space-between'} mb="4px">
        <Text textAlign={'start'} textDecoration={'underline'}>
          Office Space Rental
        </Text>
        <EditPanel
          isEditing={isEditing}
          isSaveDisabled={isSaveDisabled()}
          saveDisabledTooltipLabel={
            'Please input all required fields in the cost item before saving'
          }
          onEditToggle={toggleEdit}
          onSave={onSave}
          onDiscard={onDiscard}
        />
      </HStack>
      {osrCost.length === 0 && (
        <Text py="8px" textColor="base.content.medium" textStyle={'body-2'}>
          - No office space rental claim -
        </Text>
      )}
      {osrCost.map((row, rowIndex) => {
        const {
          description,
          currency,
          exchangeRate,
          durationInMonths,
          monthlyCost,
        } = row
        return (
          <Card
            key={rowIndex}
            mb={'4px'}
            boxShadow={'none'}
            border={'1px solid'}
            borderColor={'gray.200'}
          >
            <CardBody>
              <>
                <VStack alignItems={'start'} spacing={1}>
                  <HStack
                    justifyContent={'space-between'}
                    w={'100%'}
                    alignItems={'center'}
                  >
                    <Text textStyle={'subhead-1'} textAlign={'left'}>
                      Description
                    </Text>
                    {osrCost.length > 1 && (
                      <HStack minW={'60px'}>
                        <Text textStyle={'subhead-3'}>
                          {rowIndex + 1} of {osrCost.length}
                        </Text>
                      </HStack>
                    )}
                    {isEditing && (
                      <DeleteCardButton
                        tooltipLabel={'Delete this cost item'}
                        onDelete={() => {
                          onDeleteCard(rowIndex)
                        }}
                      />
                    )}
                  </HStack>
                  <EditableText
                    isEditing={isEditing}
                    value={description}
                    onInputChange={(newVal) => {
                      onEditFieldChange(rowIndex, {
                        description: newVal,
                      })
                    }}
                    placeholder={'Description'}
                    textStyle={'body-1'}
                  />
                  <HStack
                    gap={0}
                    textAlign={'start'}
                    justifyContent={'space-between'}
                    w={'100%'}
                  >
                    <Text textStyle={'subhead-2'} flexShrink={0}>
                      Rental Duration (months)
                    </Text>
                    <EditableNumberInput
                      isEditing={isEditing}
                      value={durationInMonths}
                      onInputChange={(newVal) => {
                        onEditFieldChange(rowIndex, {
                          durationInMonths: newVal,
                        })
                      }}
                      numberInputOpts={{ min: 1, max: 12 }}
                      placeholder={'Duration'}
                      textStyle="body-2"
                    />
                  </HStack>
                  <HStack
                    gap={0}
                    textAlign={'start'}
                    justifyContent={'space-between'}
                    w={'100%'}
                  >
                    <Text textStyle={'subhead-2'} flexShrink={0}>
                      Monthly Rental Cost
                    </Text>
                    <EditableNumberInput
                      isEditing={isEditing}
                      value={monthlyCost}
                      onInputChange={(newVal) => {
                        onEditFieldChange(rowIndex, {
                          monthlyCost: newVal,
                        })
                      }}
                      placeholder={'Monthly Cost'}
                      textStyle="body-2"
                    />
                  </HStack>
                </VStack>
                <Divider my="8px" />
                <QualifyingCostCalculation
                  formFieldName={'cost_breakdown_obj.officeSpaceRentals'}
                  currency={currency}
                  rowIndex={rowIndex}
                  exchangeRate={exchangeRate}
                  isEditing={isEditing}
                  onEditFieldChange={onEditFieldChange}
                  applicationDate={applicationDate}
                />
              </>
            </CardBody>
          </Card>
        )
      })}
      {isEditing && (
        <AddCardButton
          onClick={onAddCard}
          my={'8px'}
          isDisabled={osrCost.length > 0}
          tooltipLabel={
            osrCost.length > 0
              ? 'You can only add 1 Office Space Rental item'
              : 'Add a new cost item'
          }
        />
      )}
    </Box>
  )
}
