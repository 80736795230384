import { Card, CardBody, Divider, HStack, Text, VStack } from '@chakra-ui/react'
import { useFormContext } from 'react-hook-form'

import { HighlightWords } from '~components/HighlightWords'
import {
  EvaluateFormInputs,
  POEvaluationCostBreakdownSalary,
  Salary,
} from '~shared/types/application.dto'

import { EditableNumberInput } from './Edit/EditableNumberInput'
import { EditableSingleSelect } from './Edit/EditableSingleSelect'
import { EditableText } from './Edit/EditableText'
import { EditPanel } from './Edit/EditPanel'
import { useEdit } from './Edit/useEdit'
import { QualifyingCostCalculation } from './QualifyingCostCalculation'

export const SalaryCards = ({
  applicationDate,
}: {
  applicationDate: string
}) => {
  const {
    watch,
    setValue,
    formState: { errors },
  } = useFormContext<EvaluateFormInputs>()
  const sCost = watch('cost_breakdown_obj.salaries')
  const errMsg = errors['cost_breakdown_obj']?.salaries?.message
  const { isEditing, toggleEdit, onSave, onDiscard } =
    useEdit<EvaluateFormInputs>('cost_breakdown_obj.salaries')

  const isSaveDisabled = () => {
    if (sCost.length === 0) {
      return true
    }

    const optionalFields: (keyof POEvaluationCostBreakdownSalary)[] = [
      'poRemarks',
    ]

    // If any of the fields except optionalFields are empty, then return true
    return sCost.some((item) => {
      const allFields = Object.keys(item) as Array<keyof typeof item>
      const requiredFields = allFields.filter(
        (field) => !optionalFields.includes(field),
      )
      return requiredFields.some((key) => {
        const value = item[key]
        return (
          value === undefined ||
          value === null ||
          (typeof value === 'string' && value.trim() === '')
        )
      })
    })
  }

  const onEditFieldChange = (index: number, newValue: Partial<Salary>) => {
    const updatedSalaries = [...sCost]
    updatedSalaries[index] = { ...updatedSalaries[index], ...newValue }
    setValue('cost_breakdown_obj.salaries', updatedSalaries)
  }

  if (sCost.length === 0) {
    return null
  }

  return (
    <>
      {isEditing && (
        <Text
          textColor={'interaction.critical.default'}
          textAlign={'start'}
          mb="4px"
        >
          {`* You are now in edit mode. Please save or discard your changes before submitting the form.`}
        </Text>
      )}
      {errMsg && (
        <Text
          textColor={'interaction.critical.default'}
          textAlign={'start'}
          mb="4px"
        >
          {`* ${errMsg}`}
        </Text>
      )}
      <HStack justifyContent={'space-between'} mb="4px">
        <Text textAlign={'start'} textDecoration={'underline'}>
          Salary
        </Text>
        <EditPanel
          isEditing={isEditing}
          isSaveDisabled={isSaveDisabled()}
          saveDisabledTooltipLabel={
            'Please input all required fields in the cost item before saving'
          }
          onEditToggle={toggleEdit}
          onSave={onSave}
          onDiscard={onDiscard}
        />
      </HStack>
      {sCost.map((row, rowIndex) => {
        const {
          name,
          currency,
          exchangeRate,
          designation,
          durationInMonths,
          monthlySalary,
          nationalityType,
        } = row

        return (
          <Card
            key={rowIndex}
            mb={'4px'}
            boxShadow={'none'}
            border={'1px solid'}
            borderColor={'gray.200'}
          >
            <CardBody>
              <>
                <VStack alignItems={'start'} spacing={1}>
                  <EditableSingleSelect
                    name={'salaries_nationalityType'}
                    items={['Singaporean', 'Singapore PR', 'Foreigner']}
                    isEditing={isEditing}
                    value={nationalityType}
                    placeholder={'Nationality Type'}
                    onInputChange={(newVal) => {
                      onEditFieldChange(rowIndex, {
                        nationalityType: newVal,
                      })
                    }}
                  >
                    <HighlightWords
                      words={['Singaporean', 'Singapore PR', 'Foreigner']}
                      chosenWord={nationalityType}
                    />
                  </EditableSingleSelect>
                  <HStack justifyContent={'space-between'} w={'100%'}>
                    <EditableText
                      isEditing={isEditing}
                      value={name}
                      onInputChange={(newVal) => {
                        onEditFieldChange(rowIndex, {
                          name: newVal,
                        })
                      }}
                      placeholder={'Name'}
                      textStyle={'subhead-1'}
                    />
                    {sCost.length > 1 && (
                      <HStack minW={'60px'}>
                        <Text textStyle={'subhead-3'}>
                          {rowIndex + 1} of {sCost.length}
                        </Text>
                      </HStack>
                    )}
                  </HStack>
                  <EditableText
                    isEditing={isEditing}
                    value={designation}
                    onInputChange={(newVal) => {
                      onEditFieldChange(rowIndex, {
                        designation: newVal,
                      })
                    }}
                    placeholder={'Designation'}
                    textStyle="body-2"
                    textColor="base.content.medium"
                  />
                  <HStack
                    gap={0}
                    textAlign={'start'}
                    justifyContent={'space-between'}
                    w={'100%'}
                  >
                    <Text textStyle={'subhead-2'} flexShrink={0}>
                      Project Involvement (months)
                    </Text>
                    <EditableNumberInput
                      isEditing={isEditing}
                      value={durationInMonths}
                      onInputChange={(newVal) => {
                        onEditFieldChange(rowIndex, {
                          durationInMonths: newVal,
                        })
                      }}
                      numberInputOpts={{ min: 1, max: 12 }}
                      placeholder={'Duration'}
                      textStyle="body-2"
                    />
                  </HStack>
                  <HStack
                    gap={0}
                    textAlign={'start'}
                    justifyContent={'space-between'}
                    w={'100%'}
                  >
                    <Text textStyle={'subhead-2'} flexShrink={0}>
                      Monthly Salary
                    </Text>
                    <EditableNumberInput
                      isEditing={isEditing}
                      value={monthlySalary}
                      onInputChange={(newVal) => {
                        onEditFieldChange(rowIndex, {
                          monthlySalary: newVal,
                        })
                      }}
                      placeholder={'Monthly Salary'}
                      textStyle="body-2"
                    />
                  </HStack>
                </VStack>
                <Divider my="8px" />
                <QualifyingCostCalculation
                  formFieldName={'cost_breakdown_obj.salaries'}
                  currency={currency}
                  rowIndex={rowIndex}
                  exchangeRate={exchangeRate}
                  isEditing={isEditing}
                  onEditFieldChange={onEditFieldChange}
                  applicationDate={applicationDate}
                />
              </>
            </CardBody>
          </Card>
        )
      })}
    </>
  )
}
