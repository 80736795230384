import { Box } from '@chakra-ui/react'
import { PropsWithChildren, Suspense } from 'react'
import { createBrowserRouter, RouterProvider } from 'react-router-dom'

import { routes } from '~constants/routes'
import { ZendeskHomeRoutes } from '~features/zendesk-home'

import FetchAndRenderHTML from './FetchAndRenderHTML'

const router = createBrowserRouter([
  {
    path: routes.zendeskHome,
    element: <ZendeskHomeRoutes />,
  },
  {
    path: routes.preview,
    element: <FetchAndRenderHTML url={window.location.href} />,
  },
  {
    path: '*',
    element: <div>404</div>,
  },
])

const WithSuspense = ({ children }: PropsWithChildren) => (
  <Suspense fallback={<Box bg="neutral.100" minH="$100vh" w="100vw" />}>
    <Box
      display="flex"
      flexDirection="column"
      minHeight="100vh"
      bgColor="brand.primary.50"
    >
      {children}
    </Box>
  </Suspense>
)

export const AppRouter = (): JSX.Element => {
  return (
    <WithSuspense>
      <RouterProvider router={router} />
    </WithSuspense>
  )
}
