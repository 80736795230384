import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  FormControl,
  FormLabel,
  Text,
  VStack,
} from '@chakra-ui/react'
import { Controller, useFormContext } from 'react-hook-form'

import { BulletPoint } from '~components/BulletPoint'
import { ActivityType } from '~shared/constants/application'
import {
  EvaluateFormInputs,
  FetchApplicationInfoResp,
} from '~shared/types/application.dto'

import { REQUIRED_MSG } from '../constant'
import { CostBreakdownSection } from '../CostBreakdown'
import { Deliverables } from '../Deliverables'
import { FormFieldComponent } from '../FormFieldComponent'
import { ApprovalRemarks } from '../RecommendationRemarks'
import { Groups, Question } from '../types'

type QuestionsProps = {
  applicationInfo: FetchApplicationInfoResp
}

const APPROVAL_QUESTIONS: Question[] = [
  {
    id: 'company_hasSufficientLocalEquity',
    label: 'Company has 30% local equity',
    type: 'true_false',
    group: Groups.COMPANY,
  },
  {
    id: 'company_isNotLimitedByGuaranteeAndNotInvestmentHoldingCoy',
    label: (
      <>
        <Text>Company is not one of the following business entities</Text>
        <BulletPoint>Company limited by guarantee</BulletPoint>
        <BulletPoint>Investment Holding company</BulletPoint>
      </>
    ),
    type: 'true_false',
    group: Groups.COMPANY,
  },
  {
    id: 'company_remarks',
    label: 'Remarks',
    type: 'textarea',
    optional: true,
    group: Groups.COMPANY,
    customProps: {
      textarea: {
        placeholder:
          'Please provide remarks if any of the above statements are false',
      },
    },
  },
  {
    id: 'eligibility_groupSalesTurnoverAndEmploymentSizeAcceptable',
    label: `Is the applicant's group sales turnover less than or equal to S$100M or is the applicant's group employment size less than or equal to 200?`,
    type: 'true_false',
    group: Groups.ELIGIBILITY,
  },
  {
    id: 'eligibility_applicantHasNotAppliedForOtherGrantsForSameScopeOfWork',
    label: `The applicant has not applied for other grants/tax support for the same scope of work`,
    type: 'true_false',
    group: Groups.ELIGIBILITY,
  },
  {
    id: 'eligibility_applicantOrVendorNotOnWatchListOrBlackListOrAlertList',
    label: `The applicant/vendor is not on the watchlist/blacklist/alertlist`,
    type: 'true_false',
    group: Groups.ELIGIBILITY,
  },
  {
    id: 'eligibility_remarks',
    label: 'Remarks',
    type: 'textarea',
    optional: true,
    group: Groups.ELIGIBILITY,
    customProps: {
      textarea: {
        placeholder:
          'Please provide remarks if any of the above statements are false',
      },
    },
  },
  {
    id: 'proposal_submittedDocsTallyWithApplicationForm',
    label:
      'The submitted documents (Quotations, contracts etc) tally with the information submitted in the application form',
    type: 'true_false',
    group: Groups.PROPOSAL,
  },
  {
    id: 'proposal_projectFallsWithinMRASupportableActivities',
    label: `The project falls within MRA's supportable activities`,
    type: 'true_false',
    group: Groups.PROPOSAL,
  },
  {
    id: 'proposal_tradeShowsIsNotSupportedUnderLEADiFMAndBoothSizeAcceptable',
    label: (
      <>
        <Text>Trade Shows</Text>
        <BulletPoint>Event is not supported under LEAD iFM</BulletPoint>
        <BulletPoint>Booth is no more than 36 sqm</BulletPoint>
      </>
    ),
    type: 'custom_radio',
    group: Groups.PROPOSAL,
    customProps: {
      custom_radio: { radioSelections: ['True', 'False', 'NA'] },
    },
    optional: true,
    shouldHide: ({ applicationData }) => {
      return applicationData.activityType !== ActivityType.tradeFairs
    },
  },
  {
    id: 'proposal_qualifyingStartDate',
    label: `Qualifying Start Date`,
    type: 'date',
    group: Groups.PROPOSAL,
  },
  {
    id: 'proposal_qualifyingEndDate',
    label: `Qualifying End Date`,
    type: 'date',
    group: Groups.PROPOSAL,
  },
  {
    id: 'proposal_remarks',
    label: 'Remarks',
    type: 'textarea',
    optional: true,
    group: Groups.PROPOSAL,
    customProps: {
      textarea: {
        placeholder:
          'Please provide remarks if any of the above statements are false',
      },
    },
  },
  {
    id: 'cost_breakdown',
    label: `Cost Breakdown`,
    type: 'cost_breakdown',
    group: Groups.COST,
  },
  {
    id: 'deliverables',
    label: 'Deliverables',
    type: 'deliverables',
    group: Groups.DELIVERABLES,
  },
  {
    id: 'recommendation_noIssueForApprovingAuthorityToTakeNote',
    label: `There is no issue that the approving authority should take note`,
    type: 'true_false',
    group: Groups.RECOMMENDATION,
  },
  {
    id: 'recommendation_approval_remarks',
    label: `Recommendation Remarks`,
    type: 'recommendation_approval_remarks',
    group: Groups.RECOMMENDATION,
  },
  {
    id: 'recommendation_claimDueDate',
    label: `Claim Due Date`,
    type: 'date',
    group: Groups.RECOMMENDATION,
  },
  {
    id: 'recommendation_customNotes',
    label: `Custom Notes (Will appear in LOF)`,
    type: 'textarea',
    customProps: {
      textarea: {
        placeholder:
          'Optional. Any other custom notes that you would like to add into the Letter of Offer. Put each note on a new line.',
      },
    },
    optional: true,
    group: Groups.RECOMMENDATION,
  },
]

export const ApprovalQuestionList = ({ applicationInfo }: QuestionsProps) => {
  const { activityType, projectName, applicationApplyDateUnix } =
    applicationInfo
  const {
    control,
    formState: { errors },
    watch,
  } = useFormContext<EvaluateFormInputs>()
  const currentFormInput = watch()

  return (
    <VStack alignItems={'start'} p="4px" mt="36px">
      <Text
        textStyle={'h5'}
        textColor={'brand.primary.500'}
        alignSelf={'center'}
        pb="12px"
      >
        Complete the following evaluations for Approval
      </Text>
      <Accordion
        allowMultiple
        w="100%"
        defaultIndex={Array.from(Object.keys(Groups), (_, index) => index)}
      >
        {APPROVAL_QUESTIONS.map((question, index) => {
          const { group } = question
          const showGroupTitle =
            index === 0 || APPROVAL_QUESTIONS[index - 1].group !== group

          if (showGroupTitle) {
            return (
              <AccordionItem key={group}>
                <>
                  <AccordionButton>
                    <Text
                      flex="1"
                      textAlign="start"
                      textStyle={'h5'}
                      textColor={'brand.primary.500'}
                    >
                      {group}
                    </Text>
                    <AccordionIcon />
                  </AccordionButton>
                  <AccordionPanel pb={4}>
                    {APPROVAL_QUESTIONS.filter((q) => q.group === group).map(
                      (filteredQuestion) => {
                        const {
                          id: filteredId,
                          label: filteredLabel,
                          shouldHide,
                          optional,
                          type,
                        } = filteredQuestion
                        if (
                          shouldHide?.({
                            formInputs: currentFormInput,
                            applicationData: applicationInfo,
                          })
                        ) {
                          return null
                        }

                        if (type === 'cost_breakdown') {
                          return (
                            <CostBreakdownSection
                              key={filteredId}
                              activityType={activityType}
                              applicationDate={applicationApplyDateUnix}
                            />
                          )
                        }
                        if (type === 'deliverables') {
                          return (
                            <Deliverables
                              key={filteredId}
                              activityType={activityType}
                            />
                          )
                        }
                        if (type === 'recommendation_approval_remarks') {
                          return (
                            <ApprovalRemarks
                              key={filteredId}
                              activityType={activityType}
                              projectName={projectName}
                            />
                          )
                        }

                        return (
                          <FormControl key={filteredId} mb="12px">
                            <FormLabel mb="4px">{filteredLabel}</FormLabel>
                            {!!errors[filteredId] && (
                              <Text
                                textAlign="start"
                                color="utility.feedback.critical"
                                pb="6px"
                              >
                                {`* ${errors[filteredId]?.message || ''}`}
                              </Text>
                            )}
                            <Controller
                              name={filteredId}
                              control={control}
                              rules={optional ? {} : { required: REQUIRED_MSG }}
                              render={({ field }) => (
                                <FormFieldComponent
                                  field={field}
                                  question={filteredQuestion}
                                  isInvalid={!!errors[filteredId]}
                                />
                              )}
                            />
                          </FormControl>
                        )
                      },
                    )}
                  </AccordionPanel>
                </>
              </AccordionItem>
            )
          }
        })}
      </Accordion>
    </VStack>
  )
}
