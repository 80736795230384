import {
  FormLabel,
  HStack,
  Radio,
  RadioGroup,
  Text,
  VStack,
} from '@chakra-ui/react'
import { Controller, useFormContext } from 'react-hook-form'

import {
  EvaluateFormInputs,
  EvaluateRecommendationType,
} from '~shared/types/application.dto'

import { REQUIRED_MSG } from './constant'

const RECOMMENDATION_OPTIONS = [
  EvaluateRecommendationType.Approval,
  EvaluateRecommendationType.Rejection,
]

export const Recommendation = () => {
  const { control } = useFormContext<EvaluateFormInputs>()

  return (
    <VStack alignItems={'start'} px="20px" py="16px">
      <FormLabel>The application is recommended for</FormLabel>
      <Controller
        name={'recommendation_recommendedForApprovalOrRejection'}
        control={control}
        rules={{ required: REQUIRED_MSG }}
        render={({ field }) => (
          <RadioGroup {...field} w="100%">
            <HStack>
              {RECOMMENDATION_OPTIONS.map((selection) => {
                return (
                  <Radio key={selection} value={selection}>
                    <Text textAlign={'start'}>{selection}</Text>
                  </Radio>
                )
              })}
            </HStack>
          </RadioGroup>
        )}
      />
    </VStack>
  )
}
