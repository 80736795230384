const baseColors = {
  red: {
    '50': '#FFF1F0',
    '100': '#FCCDCA',
    '200': '#FAA6A0',
    '300': '#F5776E',
    '400': '#EB4438',
    '500': '#D62215',
    '600': '#A81B11',
    '700': '#80140D',
    '800': '#570F0A',
    '900': '#300906',
  },
  blue: {
    '50': '#EDF0FC',
    '100': '#D7DEF7',
    '200': '#B0BCEB',
    '300': '#8595D6',
    '400': '#5A6EBF',
    '500': '#3B52AD',
    '600': '#243C9C',
    '700': '#152B82',
    '800': '#12215C',
    '900': '#0D173B',
  },
  grey: {
    '50': '#F5F8FC',
    '100': '#E4E9F2',
    '200': '#D1D8E3',
    '300': '#BAC3D1',
    '400': '#98A2B3',
    '500': '#798394',
    '600': '#5D6878',
    '700': '#444E5E',
    '800': '#2F3847',
    '900': '#1B2330',
  },
  green: {
    '50': '#EBFFF6',
    '100': '#C0FAE0',
    '200': '#99F2CA',
    '300': '#64E3AA',
    '400': '#32CF88',
    '500': '#0EB067',
    '600': '#098F52',
    '700': '#087041',
    '800': '#075431',
    '900': '#063821',
  },
  yellow: {
    '50': '#fffae1',
    '100': '#FFDA68',
    '200': '#E2B73E',
    '300': '#C4992A',
    '400': '#A77C18',
    '500': '#8B6005',
    '600': '#704C00',
    '700': '#624200',
    '800': '#533800',
    '900': '#3f2b00',
  },
  slate: {
    '50': '#f8f9fa',
    '100': '#e9eaee',
    '200': '#babecb',
    '300': '#9aa0b3',
    '400': '#7b849c',
    '500': '#5d6785',
    '600': '#465173',
    '700': '#3c4764',
    '800': '#333c56',
    '900': '#272d41',
  },
  skin: {
    '1base': '#FFCAA4',
    '1shadow': '#F5B896',
    '2base': '#EBA687',
    '2shadow': '#E29B7B',
    '3base': '#D98F6F',
    '3shadow': '#CE8361',
    '4base': '#BF7657',
    '4shadow': '#AF6D53',
    '5base': '#9F634F',
    '5shadow': '#8B5544',
    '6base': '#764738',
    '6shadow': '#633D32',
  },
  standard: {
    white: '#ffffff',
    black: '#000000',
  },
}

const brand = {
  primary: {
    '50': '#F6FAFB',
    '100': '#DCECEE',
    '200': '#96C7CC',
    '300': '#64ABB4',
    '400': '#498E96',
    '500': '#3A7076',
    '600': '#25484D',
    '700': '#224145',
    '800': '#1E3A3E',
    '900': '#193234',
  },
  secondary: {
    '50': '#F7FBF7',
    '100': '#E3F1DF',
    '200': '#B7C2B4',
    '300': '#9AA397',
    '400': '#7E867C',
    '500': '#636A62',
    '600': '#50544E',
    '700': '#454944',
    '800': '#3B3E3A',
    '900': '#2C2F2B',
  },
}

export const colors = {
  green: {
    ...baseColors.green,
  },
  brand: {
    ...brand,
  },
  utility: {
    feedback: {
      info: baseColors.blue['500'],
      'info-subtle': baseColors.blue['50'],
      warning: baseColors.yellow['100'],
      'warning-subtle': baseColors.yellow['50'],
      success: baseColors.green['500'],
      'success-subtle': baseColors.green['50'],
      critical: baseColors.red['500'],
      'critical-subtle': baseColors.red['50'],
    },
    'focus-default': baseColors.blue['500'],
    'focus-inverse': baseColors.standard.white,
    'input-prefilled': baseColors.yellow['50'],
    ui: baseColors.standard.white,
    'ui-clear': hexToRgba(baseColors.standard.white, 0),
  },
  blue: {
    ...baseColors.blue,
  },
  red: {
    ...baseColors.red,
  },
  yellow: {
    ...baseColors.yellow,
  },
  slate: {
    ...baseColors.slate,
  },
  grey: {
    ...baseColors.grey,
  },
  standard: {
    ...baseColors.standard,
  },
  skin: {
    ...baseColors.skin,
  },
  base: {
    canvas: {
      default: baseColors.standard.white,
      alt: baseColors.grey['50'],
      backdrop: baseColors.grey['100'],
      'brand-subtle': baseColors.blue['50'],
      inverse: baseColors.grey['800'],
      overlay: hexToRgba(baseColors.grey['800'], 0.8),
    },
    content: {
      default: baseColors.grey['700'],
      strong: baseColors.grey['900'],
      medium: baseColors.grey['500'],
      brand: brand.primary['500'],
      inverse: baseColors.standard.white,
    },
    divider: {
      subtle: baseColors.grey['50'],
      medium: baseColors.grey['100'],
      strong: baseColors.grey['200'],
      inverse: baseColors.standard.white,
      brand: brand.primary['500'],
    },
  },
  interaction: {
    main: {
      default: brand.primary['500'],
      hover: brand.primary['600'],
      active: brand.primary['700'],
    },
    'main-subtle': {
      default: brand.primary['100'],
      hover: brand.primary['200'],
      active: brand.primary['300'],
    },
    sub: {
      default: brand.secondary['50'],
      hover: brand.secondary['600'],
      active: brand.secondary['700'],
    },
    'sub-subtle': {
      default: brand.secondary['100'],
      hover: brand.secondary['200'],
      active: brand.secondary['300'],
    },
    critical: {
      default: baseColors.red['500'],
      hover: baseColors.red['600'],
      active: baseColors.red['700'],
    },
    'critical-subtle': {
      default: baseColors.red['100'],
      hover: baseColors.red['200'],
      active: baseColors.red['300'],
    },
    warning: {
      default: baseColors.yellow['100'],
      hover: baseColors.yellow['200'],
      active: baseColors.yellow['300'],
    },
    'warning-subtle': {
      default: baseColors.yellow['50'],
      hover: baseColors.yellow['100'],
      active: baseColors.yellow['200'],
    },
    success: {
      default: baseColors.green['500'],
      hover: baseColors.green['600'],
      active: baseColors.green['700'],
    },
    'success-subtle': {
      default: baseColors.green['100'],
      hover: baseColors.green['200'],
      active: baseColors.green['300'],
    },
    neutral: {
      default: baseColors.grey['700'],
      hover: baseColors.grey['400'],
      active: baseColors.grey['500'],
    },
    'neutral-subtle': {
      default: baseColors.grey['50'],
      hover: baseColors.grey['100'],
      active: baseColors.grey['200'],
    },
    muted: {
      main: {
        hover: brand.primary['50'],
        active: brand.primary['700'],
      },
      sub: {
        hover: brand.secondary['50'],
        active: brand.secondary['100'],
      },
      critical: {
        hover: baseColors.red['50'],
        active: baseColors.red['100'],
      },
      neutral: {
        hover: baseColors.grey['50'],
        active: baseColors.grey['100'],
      },
    },
    tinted: {
      main: {
        hover: hexToRgba(brand.primary['500'], 0.04),
        active: hexToRgba(brand.primary['500'], 0.12),
      },
      sub: {
        hover: hexToRgba(brand.secondary['500'], 0.04),
        active: hexToRgba(brand.secondary['500'], 0.12),
      },
      critical: {
        hover: hexToRgba(baseColors.red['500'], 0.04),
        active: hexToRgba(baseColors.red['500'], 0.12),
      },
      neutral: {
        hover: hexToRgba(baseColors.standard.black, 0.1),
        active: hexToRgba(baseColors.standard.black, 0.2),
      },
      inverse: {
        hover: hexToRgba(baseColors.standard.white, 0.1),
        active: hexToRgba(baseColors.standard.white, 0.2),
      },
    },
    support: {
      unselected: baseColors.grey['300'],
      selected: baseColors.grey['500'],
      'unselected-strong': baseColors.grey['500'],
      disabled: baseColors.grey['100'],
      'disabled-content': baseColors.grey['300'],
      placeholder: baseColors.grey['400'],
    },
    links: {
      default: '#005DEA',
      hover: '#004BBA',
      'neutral-default': baseColors.grey['700'],
      'neutral-hover': baseColors.grey['900'],
      'inverse-default': baseColors.standard.white,
      'inverse-hover': baseColors.grey['100'],
    },
  },
  background: '#F6F9FF',
}

function hexToRgba(hex: string, alpha = 1): string {
  // Ensure hex starts with a '#'
  let sanitizedHex = hex.charAt(0) === '#' ? hex.substring(1) : hex

  // Handle short form like "#FFF"
  if (sanitizedHex.length === 3) {
    sanitizedHex = sanitizedHex
      .split('')
      .map((char) => char + char)
      .join('')
  }

  if (sanitizedHex.length !== 6) {
    throw new Error('Invalid hex color provided.')
  }

  const r = parseInt(sanitizedHex.slice(0, 2), 16)
  const g = parseInt(sanitizedHex.slice(2, 4), 16)
  const b = parseInt(sanitizedHex.slice(4, 6), 16)

  return `rgba(${r}, ${g}, ${b}, ${alpha})`
}
