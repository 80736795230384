import {
  POEvaluationCostBreakdownSalaries,
  POEvaluationCostBreakdownThirdPartyVendors,
  POEvaluationOfficeSpaceRentals,
} from '../types/application.dto'
import { EligibleCostItem, EligibleCostItems } from '../types/pdfDocument.dto'

/**
 * Helper function to safely multiply a given cost and support level
 * Use to derive grant amount value
 * @param cost
 * @param percentage
 */
export const calculateGrantAmount = (cost: string, percentage: string) => {
  const qcAmount = isNaN(Number(cost)) ? 0 : Number(cost)
  const supportLevel = isNaN(Number(percentage)) ? 0 : Number(percentage) / 100
  return qcAmount * supportLevel
}

/**
 * Takes in the PO Evaluation form and returns the cost items.
 */
export const computeCostItems = (costBreakdownObj: {
  thirdPartyVendors: POEvaluationCostBreakdownThirdPartyVendors
  salaries: POEvaluationCostBreakdownSalaries
  officeSpaceRentals: POEvaluationOfficeSpaceRentals
}): EligibleCostItems => {
  const { thirdPartyVendors, salaries, officeSpaceRentals } = costBreakdownObj

  const thirdPartyVendorItems: EligibleCostItem[] = thirdPartyVendors.map(
    (vendor) => {
      return {
        eligibleExpense: vendor.vendorNameOrUen,
        levelOfSupport: vendor.supportLevel,
        billingCurrency: vendor.currency,
        appliedAmountInBillingCurrency: vendor.amount,
        exchangeRate: vendor.exchangeRate,
        qualifyingCostInSGD: vendor.qualifyingAmt,
        grant: calculateGrantAmount(
          getEquivalentInCurrency(
            vendor.qualifyingAmt,
            vendor.exchangeRate,
          ).toString(),
          vendor.supportLevel,
        ).toFixed(2),
        poRemarks: vendor.poRemarks,
      }
    },
  )

  const salaryItems: EligibleCostItem[] = salaries.map((salary) => {
    return {
      eligibleExpense: salary.name,
      levelOfSupport: salary.supportLevel,
      billingCurrency: salary.currency,
      appliedAmountInBillingCurrency: calculateBillableAmt(
        salary.monthlySalary,
        salary.durationInMonths,
      ),
      exchangeRate: salary.exchangeRate,
      qualifyingCostInSGD: salary.qualifyingAmt,
      grant: calculateGrantAmount(
        getEquivalentInCurrency(
          salary.qualifyingAmt,
          salary.exchangeRate,
        ).toString(),
        salary.supportLevel,
      ).toFixed(2),
      poRemarks: salary.poRemarks,
    }
  })

  const officeSpaceRentalItems: EligibleCostItem[] = officeSpaceRentals.map(
    (rental) => {
      return {
        eligibleExpense: rental.description,
        levelOfSupport: rental.supportLevel,
        billingCurrency: rental.currency,
        appliedAmountInBillingCurrency: calculateBillableAmt(
          rental.monthlyCost,
          rental.durationInMonths,
        ),
        exchangeRate: rental.exchangeRate,
        qualifyingCostInSGD: rental.qualifyingAmt,
        grant: calculateGrantAmount(
          getEquivalentInCurrency(
            rental.qualifyingAmt,
            rental.exchangeRate,
          ).toString(),
          rental.supportLevel,
        ).toFixed(2),
        poRemarks: rental.poRemarks,
      }
    },
  )

  return {
    thirdPartyVendors: thirdPartyVendorItems,
    salaries: salaryItems,
    officeSpaceRentals: officeSpaceRentalItems,
  }
}

/**
 * Compute the total grant amount from the cost items.
 */
export const computeTotalGrantAmt = (costItems: EligibleCostItems): number => {
  const allCostItems = [
    ...costItems.thirdPartyVendors,
    ...costItems.salaries,
    ...costItems.officeSpaceRentals,
  ]

  return allCostItems.reduce((acc, item) => {
    return acc + Number(item.grant)
  }, 0)
}

/**
 * Converts a native foreign currency amount into another currency amount
 * @param currencyAmount
 * @param exchangeRate
 */
export const getEquivalentInCurrency = (
  currencyAmount: string,
  exchangeRate: string,
): number => {
  const originalAmt = Number(currencyAmount)
  const rate = Number(exchangeRate)
  return (isNaN(originalAmt) ? 0 : originalAmt) * (isNaN(rate) ? 1 : rate)
}

/**
 * Billable Amount is calculated by multiplying the monthly cost by the number of months.
 * Handles common scenario of monthly cost containing commas, spaces, and dollar signs.
 * If monthly cost or number of months is not a valid number, return empty string.
 * E.g. calculateBillableAmt('1,000', '12') => '12000'
 * @param monthlyCost How much it cost per month
 * @param numMonths Number of months
 * @returns monthlyCost * numMonths if inputs are valid, empty string otherwise
 */
export const calculateBillableAmt = (
  monthlyCost: string,
  numMonths: string,
): string => {
  // strips out commas, spaces, and dollar signs
  const cleanMonthlyCost = monthlyCost.replace(/[,\s$]+/g, '')
  const cleanNumMonths = numMonths.replace(/[,\s$]+/g, '')

  // Convert the cleaned strings to numbers
  const monthlyCostNum = parseFloat(cleanMonthlyCost)
  const numMonthsNum = parseInt(cleanNumMonths, 10)

  // Check for valid numerical inputs after cleaning and ensure they are not negative
  if (
    isNaN(monthlyCostNum) ||
    isNaN(numMonthsNum) ||
    monthlyCostNum < 0 ||
    numMonthsNum < 0
  ) {
    return ''
  }

  const billableAmt = monthlyCostNum * numMonthsNum

  return billableAmt.toString()
}
