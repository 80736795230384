import { Text, Wrap, WrapItem } from '@chakra-ui/react'
import { Fragment } from 'react'

type HighlightWordsProps = {
  words: string[]
  chosenWord: string
  capitalizedChoseWord?: boolean
}

// HighlightWords is a component that takes in a list of words and a chosen word. It will render the list of words with the chosen word in bold.
export const HighlightWords = ({
  words,
  chosenWord,
  capitalizedChoseWord,
}: HighlightWordsProps) => {
  return (
    <Wrap align={'center'}>
      {words.map((word, index) => (
        <Fragment key={word}>
          {index > 0 && (
            <WrapItem>
              <Text textStyle={'body-2'}>/</Text>
            </WrapItem>
          )}
          <WrapItem>
            <Text
              textStyle={
                capitalizedChoseWord && chosenWord === word
                  ? 'subhead-3'
                  : 'body-2'
              }
              fontWeight={chosenWord === word ? 'bold' : 'normal'}
              color={chosenWord === word ? 'black' : 'grey.400'}
            >
              {word}
            </Text>
          </WrapItem>
        </Fragment>
      ))}
    </Wrap>
  )
}
