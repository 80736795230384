import { Button, HStack, Tooltip } from '@chakra-ui/react'
import { IconButton } from '@opengovsg/design-system-react'
import { BiSolidEditAlt } from 'react-icons/bi'

type EditPanelProps = {
  isEditing: boolean
  saveDisabledTooltipLabel: string
  isSaveDisabled?: boolean
  onEditToggle: () => void
  onSave: () => void
  onDiscard: () => void
}

export const EditPanel = ({
  isEditing,
  isSaveDisabled,
  saveDisabledTooltipLabel,
  onEditToggle,
  onSave,
  onDiscard,
}: EditPanelProps) => {
  return (
    <>
      {!isEditing && (
        <IconButton
          aria-label="Edit"
          variant="clear"
          size="xs"
          onClick={onEditToggle}
        >
          <BiSolidEditAlt size="20px" />
        </IconButton>
      )}
      {isEditing && (
        <HStack>
          <Button variant="outline" size="xs" onClick={onDiscard}>
            Discard
          </Button>
          <Tooltip
            label={saveDisabledTooltipLabel}
            isDisabled={!isSaveDisabled}
            hasArrow
            openDelay={1000}
          >
            <Button size="xs" onClick={onSave} isDisabled={isSaveDisabled}>
              Save
            </Button>
          </Tooltip>
        </HStack>
      )}
    </>
  )
}
