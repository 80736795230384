import {
  Box,
  FormControl,
  FormLabel,
  Text,
  Tooltip,
  VStack,
} from '@chakra-ui/react'
import { Button, BxPlus, Textarea } from '@opengovsg/design-system-react'
import { Controller, useFormContext } from 'react-hook-form'

import { REQUIRED_MSG } from '~features/zendesk-home/tabs/Evaluation/constant'
import { ActivityType } from '~shared/constants/application'
import { EvaluateFormInputs } from '~shared/types/application.dto'
import { formatNumberAsSingaporeCurrency } from '~shared/utils/string'

export const ApprovalRemarks = ({
  activityType,
  projectName,
}: {
  activityType: ActivityType
  projectName: string
}) => {
  const {
    control,
    watch,
    setValue,
    formState: { errors },
  } = useFormContext<EvaluateFormInputs>()
  const finalGrantAmount = watch('costSummary_finalGrantAmount')
  const populateRecommendationRemarks = () => {
    const baseTemplate = `Applicant meets SME criteria and local shareholding, and has correctly classified this project ${projectName} under ${activityType} activity pillar. Company is new to market, and PO assessed the scope and supportability of the project and conducted cost reasonableness.`
    const grantTemplate = `The recommended MRA grant of ${formatNumberAsSingaporeCurrency(
      Number(finalGrantAmount),
    )} is within the grant limit.`

    setValue(
      'recommendation_approval_remarks',
      baseTemplate + '\n\n' + grantTemplate,
      { shouldValidate: true },
    )
  }
  return (
    <Box>
      <FormControl key={'recommendation_approval_remarks'} mb="12px">
        <VStack alignItems={'start'} gap={0}>
          <FormLabel mb={0}>Recommendation Remarks</FormLabel>
          {!!errors['recommendation_approval_remarks'] && (
            <Text textAlign="start" color="utility.feedback.critical" pb="6px">
              {`* ${errors['recommendation_approval_remarks']?.message || ''}`}
            </Text>
          )}

          <Tooltip
            label={
              'Please fill in the Final Grant Amount to enable auto-populate'
            }
            isDisabled={!!finalGrantAmount}
          >
            <Button
              size={'xs'}
              variant={'solid'}
              textAlign={'start'}
              isDisabled={!finalGrantAmount}
              leftIcon={<BxPlus fontSize={'1rem'} />}
              onClick={populateRecommendationRemarks}
            >
              Auto-Populate
            </Button>
          </Tooltip>
          <Controller
            name={'recommendation_approval_remarks'}
            control={control}
            rules={{ required: REQUIRED_MSG }}
            render={({ field }) => <Textarea {...field} />}
          />
        </VStack>
      </FormControl>
    </Box>
  )
}
