import { Box, FormControl, Text } from '@chakra-ui/react'
import {
  Checkbox,
  FormErrorMessage,
  FormLabel,
} from '@opengovsg/design-system-react'
import { Controller, useFormContext } from 'react-hook-form'

import { EvaluateFormInputs } from '~shared/types/application.dto'

import { REQUIRED_MSG } from './constant'

export const Declaration = () => {
  const {
    control,
    formState: { errors },
  } = useFormContext<EvaluateFormInputs>()
  const errorMsg = errors['poDeclaration_declaration']?.message

  return (
    <Box px="20px" py="16px">
      <FormControl isInvalid={!!errorMsg}>
        <FormLabel isRequired>Declaration</FormLabel>
        <FormErrorMessage>{errorMsg}</FormErrorMessage>
        <Controller
          name={'poDeclaration_declaration'}
          control={control}
          rules={{ required: REQUIRED_MSG }}
          render={({ field }) => (
            <Checkbox
              onChange={field.onChange}
              borderColor={'utility.feedback.critical'}
              borderWidth={errorMsg ? 1 : undefined}
            >
              <Text textAlign={'start'}>
                <Text>
                  I hereby declare that I do not have any conflict of interest*
                  nor am I aware that any of my immediate family members
                  (parents, siblings, spouses and children) have any interest in
                  relation to the application, claim or project.
                </Text>
                <br />
                <Text>
                  Otherwise, please re-assign this application to another
                  Processing Officer.
                </Text>
                <br />
                <Text textStyle={'caption-1'} textColor={'base.content.medium'}>
                  *Conflict of interest could arise in situations where a person
                  is in a position to exert undue influence for personal gains
                  or gain for any other persons, as a result of his/her official
                  capacity. All staff are reminded that private interests (i.e.
                  gain a benefit or favour from the action or due to information
                  not available to public) should be kept separate from public
                  duties. Whenever in doubt, please declare to your reporting
                  officers and recuse yourself accordingly.
                </Text>
              </Text>
            </Checkbox>
          )}
        />
      </FormControl>
    </Box>
  )
}
