import { Box, Text } from '@chakra-ui/react'
import { Checkbox } from '@opengovsg/design-system-react'
import { UseFormRegister } from 'react-hook-form'

import {
  ReworkFormInputs,
  ReworkTemplatesItem,
} from '~shared/types/application.dto'

import { ReworkItemToolTip } from './ReworkItemTooltip'

type ReworkSectionProps = {
  category: string
  items: ReworkTemplatesItem[]
  register: UseFormRegister<ReworkFormInputs>
}

export const ReworkSection = ({
  category,
  items,
  register,
}: ReworkSectionProps) => {
  if (items.length === 0) {
    return null
  }

  return (
    <Box textAlign="start">
      <Text textStyle="h5" textColor="brand.primary.500" my="8px">
        {category}
      </Text>
      {items.map((item) => (
        <ReworkItemToolTip key={item.key} item={item}>
          <Checkbox {...register(item.key)}>{item.label}</Checkbox>
        </ReworkItemToolTip>
      ))}
    </Box>
  )
}
