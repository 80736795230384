import { FaCheckCircle, FaQuestionCircle, FaTimesCircle } from 'react-icons/fa'

interface OutcomeIndicatorProps {
  outcome?: boolean
}

export const OutcomeIndicator = ({
  outcome,
}: OutcomeIndicatorProps): JSX.Element => {
  if (outcome === undefined) {
    return <FaQuestionCircle color={'grey'} />
  }
  return outcome ? (
    <FaCheckCircle color="green" />
  ) : (
    <FaTimesCircle color="red" />
  )
}
