/**
 * Extracts a file name from a URL, assuming the fileName is the last part of the URL.
 * E.g. https://www.example.com/path/to/file.txt -> file.txt
 * E.g. https://www.example.com/path/to/file.txt?query=param -> file.txt
 */
export const extractFileNameFromUrl = (url: string): string => {
  // Separate the path from the query parameters
  const [path] = url.split('?')

  const lastSlashIndex = path.lastIndexOf('/')

  return path.substring(lastSlashIndex + 1)
}

/**
 * Return a new string that capitalize the start of each word.
 * E.g. 'hello world' -> 'Hello World'
 *      'HELLO WORLD' -> 'Hello World'
 */
export const capitalizeFirstLetter = (str = ''): string => {
  return str
    .split(' ')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
    .join(' ')
}

/**
 * Takes in a number or a string, and if it's a valid number, returns a thousandth-separtor formatted as Singapore currency with 2 dp.
 * If the number is negative, it is formatted with parentheses.
 * If the string is not a valid number, returns the original string.
 * E.g. 1305003 -> "SGD 1,305,003.00"
 * E.g. "135.5" -> "SGD 135.50"
 * E.g. 120 -> "SGD 120.00"
 * E.g. "-15600.00" -> "(SGD 15,600.00)"
 * E.g. -0 -> "SGD 0.00"
 * E.g. "not a number" -> "not a number"
 */
export const formatNumberAsSingaporeCurrency = (
  input: number | string,
): string => {
  const num = typeof input === 'string' ? parseFloat(input) : input

  if (isNaN(num)) {
    return typeof input === 'string' ? input : num.toString()
  }

  const formatter = new Intl.NumberFormat('en-SG', {
    style: 'currency',
    currency: 'SGD',
    currencyDisplay: 'code',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  })

  const formattedNum = formatter.format(Math.abs(num))

  return num < 0 ? `(${formattedNum})` : formattedNum
}

/**
 * Takes in a string, and returns a new string with the prefix removed if it exists.
 * @param inputString The string to remove the prefix from
 * @param prefix The prefix to remove
 * @returns The new string with the prefix removed
 */
export const removePrefix = (inputString: string, prefix: string): string => {
  if (inputString.startsWith(prefix)) {
    return inputString.substring(prefix.length)
  }

  return inputString
}

/**
 * Mask the NRIC and return the masked NRIC.
 * E.g. S1234567A -> Sxxxx567A
 */
export const maskNric = (nric: string): string =>
  nric.replace(nric.substring(1, 5), 'xxxx')

/**
 * Format a text number when possible, or return the default text value
 * @param text {string} The text to format as a number
 * @returns string
 */
export const formatNumberOrDefault = (text: string | number): string => {
  if (text === '') return ''
  const num = Number(text)
  return isNaN(num) ? text.toString() : new Intl.NumberFormat().format(num)
}

/**
 * Helper function to extract currency code given a currency string (primarily for formsg submitted data)
 * @param currency Currency format in the form CurrencyName - CurrencyCode
 * @returns string The currency code
 * E.g. Singapore Dollar - SGD -> SGD
 * E.g. Chinese Yuan - CNY -> CNY
 */
export const getCurrencyCodeFromCurrency = (currency: string): string => {
  try {
    const parts = currency.split('-')
    if (parts.length !== 2) return ''
    return parts[1].trim()
  } catch (e) {
    throw new Error(`Unable to extract currency code from currency ${currency}`)
  }
}
