import { Text, TextProps } from '@chakra-ui/react'
import { SingleSelect, SingleSelectProps } from '@opengovsg/design-system-react'
import { PropsWithChildren } from 'react'

type EditableSingleSelectProps = {
  name: string
  items: SingleSelectProps['items']
  isEditing: boolean
  value: string
  onInputChange: (value: string) => void
  placeholder?: string
  isDisabled?: boolean
} & TextProps

// Get the display label for the selected value.
// If items is of type `string[]`, then display label is simply the string.
// If items is of type `{ label: string, value: string }[]`, then display label is the label if it exists, otherwise the value.
const getDisplayLabel = (value: string, items: SingleSelectProps['items']) => {
  const item = items.find((item) => {
    if (typeof item === 'string' || item === null) {
      return item === value
    }
    return item.value === value
  })
  // If the item is an object and has a label, use it. Otherwise, use the value.
  return typeof item === 'object' && item !== null
    ? item.label || item.value
    : value
}

export const EditableSingleSelect = ({
  name,
  items,
  isEditing,
  value,
  onInputChange,
  placeholder,
  isDisabled,
  children,
  ...textProps
}: PropsWithChildren<EditableSingleSelectProps>) => {
  if (isEditing) {
    return (
      <SingleSelect
        name={name}
        items={items}
        size="xs"
        value={value}
        onChange={onInputChange}
        isClearable={false}
        placeholder={placeholder}
        isDisabled={isDisabled}
      />
    )
  }

  if (children) {
    return <>{children}</>
  }

  return (
    <Text textAlign={'start'} {...textProps}>
      {getDisplayLabel(value, items)}
    </Text>
  )
}
