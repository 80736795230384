import { Box, BoxProps, Tooltip } from '@chakra-ui/react'
import { IconButton } from '@opengovsg/design-system-react'
import { IoIosAddCircle } from 'react-icons/io'

type AddCardButtonProps = {
  tooltipLabel: string
  onClick: () => void
  isDisabled?: boolean
} & BoxProps

export const AddCardButton = ({
  onClick,
  tooltipLabel,
  isDisabled,
  ...boxProps
}: AddCardButtonProps) => {
  return (
    <Box {...boxProps}>
      <Tooltip label={tooltipLabel} openDelay={1000} hasArrow>
        <IconButton
          aria-label="Add Card"
          size="xs"
          variant="clear"
          onClick={onClick}
          isRound={true}
          isDisabled={isDisabled}
        >
          <IoIosAddCircle size="42px" />
        </IconButton>
      </Tooltip>
    </Box>
  )
}
