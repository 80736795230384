import { Divider, HStack, Text, VStack } from '@chakra-ui/react'
import { Tooltip } from '@opengovsg/design-system-react'
import { BsArrowRight, BsDash } from 'react-icons/bs'

import { OutcomeIndicator } from '~components/OutcomeIndicator'
import { FdpInsightsResp } from '~shared/types/fdp.dto'

export type FdpInformationProps = {
  fdpData: FdpInsightsResp
}

export const FdpInformation = ({ fdpData }: FdpInformationProps) => {
  return (
    <VStack w={'100%'}>
      {/* render in the following format: failed, unresolved, pass */}
      {/* if no all pass, show a header that says FDP checks pass*/}
      {fdpData.pass &&
        fdpData.pass?.length > 0 &&
        (!fdpData.fail || fdpData.fail.length === 0) &&
        (!fdpData.unresolved || fdpData.unresolved.length === 0) && (
          <VStack w={'100%'} p={'10px'} alignItems={'start'}>
            <HStack>
              <OutcomeIndicator outcome={true} />
              <Text color={'green'}>All FDP checks passed</Text>
            </HStack>
          </VStack>
        )}
      {fdpData.fail && fdpData.fail.length > 0 && (
        <VStack
          w={'100%'}
          p={'10px'}
          alignItems={'start'}
          gap={2}
          border={'1px solid grey'}
          backgroundColor={'red.50'}
          transition={'all 0.5s ease'}
          borderRadius={'8px'}
        >
          {fdpData.fail.map((f) => {
            return (
              <HStack key={f.label} alignItems={'start'} gap={0} w={'100%'}>
                <OutcomeIndicator outcome={false} />
                <VStack alignItems={'start'} gap={0} textAlign={'left'}>
                  <Text textStyle={'caption-2'}>{f.label}</Text>
                  {f.relationship &&
                    f.relationship.map((r) => (
                      <VStack
                        alignItems={'start'}
                        gap={0}
                        key={`${r.from}-${r.relationship}-${r.to}`}
                      >
                        <HStack justifyContent={'space-between'}>
                          <Text textStyle={'code-2'}>{r.from}</Text>
                          <BsDash />
                          <Text textStyle={'code-2'} fontWeight={'800'}>
                            {r.relationship.toUpperCase()}
                          </Text>
                          {r.isDirected ? <BsArrowRight /> : <BsDash />}
                          <Text textStyle={'code-2'}>{r.to}</Text>
                        </HStack>
                        <Divider />
                      </VStack>
                    ))}
                  <VStack>
                    {f.description &&
                      f.description.map((d) => (
                        <Text textStyle={'legal'} key={d}>
                          {d}{' '}
                        </Text>
                      ))}
                  </VStack>
                </VStack>
              </HStack>
            )
          })}
        </VStack>
      )}
      {fdpData.unresolved && fdpData.unresolved.length > 0 && (
        <Tooltip
          label={
            'This section contains fraud detections checks that were unresolved or inconclusive. Please contact the OGP team if it happens.'
          }
          fontSize={'12px'}
        >
          <VStack
            w={'100%'}
            p={'10px'}
            alignItems={'start'}
            gap={2}
            border={'1px solid grey'}
            backgroundColor={'grey.50'}
            transition={'all 0.5s ease'}
            borderRadius={'8px'}
          >
            {fdpData.unresolved.map((u) => (
              <HStack key={u.label} alignItems={'start'} gap={0} w={'100%'}>
                <OutcomeIndicator />
                <Text textStyle={'caption-2'} textAlign={'left'}>
                  {u.label}
                </Text>
              </HStack>
            ))}
          </VStack>
        </Tooltip>
      )}
      {fdpData.pass && fdpData.pass.length > 0 && (
        <VStack
          w={'100%'}
          p={'10px'}
          alignItems={'start'}
          gap={2}
          border={'1px solid grey'}
          backgroundColor={'green.50'}
          transition={'all 0.5s ease'}
          borderRadius={'8px'}
        >
          {fdpData.pass.map((p) => (
            <HStack key={p.label} alignItems={'start'} gap={0} w={'100%'}>
              <OutcomeIndicator outcome={true} />
              <Text textStyle={'caption-2'} textAlign={'left'}>
                {p.label}
              </Text>
            </HStack>
          ))}
        </VStack>
      )}
    </VStack>
  )
}
