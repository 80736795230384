import { useEffect, useState } from 'react'

import { Client } from '~/types/zendeskClient'
import { CurrentUser } from '~shared/types/zendeskUser.dto'
import { isDevEnv } from '~utils/utils'

export function useZendeskClient() {
  const [zendeskClient, setZendeskClient] = useState<Client | null>(null)
  const [isLoadedWithinZendeskApp, setIsLoadedWithinZendeskApp] = useState(true)
  const [ticketId, setTicketId] = useState<number | null>(null)
  const [currentUser, setCurrentUser] = useState<CurrentUser | null>(null)

  useEffect(() => {
    const init = async () => {
      if (!zendeskClient && window.ZAFClient) {
        /**
         * client will be `false` if we load it in a normal browser.
         * If we load it in a Zendesk app, it will be an object that contains the zendesk sdk functions.
         * We don't need to use any auth as Zendesk sdk will use the current zendesk session when we load this page in the zendesk app.
         */
        const client = window.ZAFClient.init()
        if (client) {
          console.log('Zendesk client exists')
          setZendeskClient(client)
          await client.invoke('resize', { width: '100%', height: '1000px' })
        } else {
          console.log('Zendesk client does not exist')
          setIsLoadedWithinZendeskApp(false)
        }
      }
    }
    void init()
  }, [zendeskClient])

  useEffect(() => {
    const fetchCurrentUser = async () => {
      if (zendeskClient && !currentUser) {
        const userRes: {
          currentUser: CurrentUser | null
        } = await zendeskClient.get('currentUser')
        setCurrentUser(userRes.currentUser)
      }
    }
    void fetchCurrentUser()
    const fetchTicketId = async () => {
      if (zendeskClient && !ticketId) {
        const ticketInfo: {
          'ticket.id': number
        } = await zendeskClient.get('ticket.id')
        const ticketId = ticketInfo['ticket.id']
        setTicketId(ticketId)
      }
    }
    void fetchTicketId()
  }, [zendeskClient, ticketId, currentUser])

  const zendeskRequest = async <T,>(
    url: string,
    method: string,
    data?: unknown,
  ): Promise<T> => {
    // use api url if provided, otherwise default to origin
    const baseApiUrl =
      process.env.REACT_APP_API_URL ?? `${window.location.origin}/v1/api`
    if (!zendeskClient) throw new Error('Zendesk client unavailable')
    const options = {
      url: baseApiUrl + url,
      type: method,
      cors: isDevEnv(),
      secure: true,
      accepts: 'application/json',

      headers: {
        'X-RFI-KEY': '{{setting.apiToken}}',
        'Content-Type': 'application/json',
      },
      data: JSON.stringify(data),
    }
    return zendeskClient.request(options)
  }

  return {
    zendeskClient,
    isLoadedWithinZendeskApp,
    zendeskRequest,
    ticketId,
    currentUser,
  }
}
