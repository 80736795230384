import { Box, BoxProps, Tooltip } from '@chakra-ui/react'
import { IconButton } from '@opengovsg/design-system-react'
import { FaTrash } from 'react-icons/fa6'

type DeleteCardButtonProps = {
  tooltipLabel: string
  onDelete: () => void
} & BoxProps

export const DeleteCardButton = ({
  tooltipLabel,
  onDelete,
  ...boxProps
}: DeleteCardButtonProps) => {
  return (
    <Box {...boxProps}>
      <Tooltip label={tooltipLabel} openDelay={1000} hasArrow>
        <IconButton
          aria-label="Delete Card"
          size="xs"
          variant="clear"
          onClick={onDelete}
        >
          <FaTrash size="18px" />
        </IconButton>
      </Tooltip>
    </Box>
  )
}
