import { Box, Button, Text } from '@chakra-ui/react'
import { useToast } from '@opengovsg/design-system-react'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { FormProvider, useForm } from 'react-hook-form'

import { QUERY_KEYS } from '~constants/queryKeys'
import { useZendeskClient } from '~lib/zendeskClient'
import {
  EvaluateFormInputs,
  EvaluateRecommendationType,
  FetchApplicationInfoResp,
} from '~shared/types/application.dto'
import { formatDateString, getClaimDueDate } from '~shared/utils/date'
import { calculateBillableAmt } from '~shared/utils/number'

import { Declaration } from './Declaration'
import { ApprovalQuestionList } from './Questions/ApprovalQuestionList'
import { RejectionQuestionList } from './Questions/RejectionQuestionList'
import { Recommendation } from './Recommendation'

type EvaluationTabProps = {
  applicationInfo: FetchApplicationInfoResp
  submittedEvaluation?: EvaluateFormInputs
}

export const EvaluationTab = ({
  applicationInfo,
  submittedEvaluation,
}: EvaluationTabProps): JSX.Element => {
  const { projectStartDate, projectEndDate, costBreakdown } = applicationInfo
  const { zendeskRequest, ticketId, currentUser } = useZendeskClient()
  const toast = useToast({ position: 'bottom', isClosable: true })
  const queryClient = useQueryClient()
  const formMethods = useForm<EvaluateFormInputs>({
    mode: 'onChange',
    defaultValues: submittedEvaluation
      ? {
          ...submittedEvaluation,
          poDeclaration_declaration: false,
        }
      : {
          proposal_qualifyingStartDate: formatDateString(projectStartDate, {
            from: 'DD MMM YYYY',
            to: 'DD/MM/YYYY',
          }),
          proposal_qualifyingEndDate: formatDateString(projectEndDate, {
            from: 'DD MMM YYYY',
            to: 'DD/MM/YYYY',
          }),
          recommendation_claimDueDate: formatDateString(
            getClaimDueDate(projectEndDate),
            { from: 'DD MMM YYYY', to: 'DD/MM/YYYY' },
          ),
          cost_breakdown_obj: {
            thirdPartyVendors: costBreakdown.thirdPartyVendors.map((tpv) => {
              return {
                ...tpv,
                qualifyingAmt: tpv.amount,
                supportLevel: '50',
                poRemarks: '',
              }
            }),
            salaries: costBreakdown.salaries.map((s) => {
              return {
                ...s,
                supportLevel: '0',
                poRemarks: '',
                qualifyingAmt: calculateBillableAmt(
                  s.monthlySalary,
                  s.durationInMonths,
                ),
              }
            }),
            officeSpaceRentals: costBreakdown.officeSpaceRentals.map((osr) => {
              return {
                ...osr,
                supportLevel: '0',
                poRemarks: '',
                qualifyingAmt: calculateBillableAmt(
                  osr.monthlyCost,
                  osr.durationInMonths,
                ),
              }
            }),
          },
          costSummary_bypassGrantCap: false,
          // default to NA in any case, and it will only be shown when activity type is trade fair
          proposal_tradeShowsIsNotSupportedUnderLEADiFMAndBoothSizeAcceptable:
            'NA',
          deliverables: '',
          company_remarks: '',
          eligibility_remarks: '',
          proposal_remarks: '',
          recommendation_approval_remarks: '',
          recommendation_rejection_remarks: '',
          recommendation_customNotes: '',
          poDeclaration_declaration: false,
        },
  })
  const {
    handleSubmit,
    watch,
    formState: { errors },
  } = formMethods

  const submitEvaluationMutation = useMutation({
    mutationFn: async (form: EvaluateFormInputs) => {
      if (!ticketId || !currentUser) {
        return
      }
      return zendeskRequest('/application/submit-evaluation', 'POST', {
        ticketId,
        ...form,
        currentUser,
      })
    },
    onSuccess: async () => {
      toast({
        status: 'success',
        description: 'Successfully submitted evaluation',
      })
      // Refetch evaluation so letter of offer tab has updated info
      await queryClient.invalidateQueries({
        queryKey: [QUERY_KEYS.FETCH_EVALUATION],
      })
    },
    onError: (error) => {
      toast({
        status: 'error',
        description:
          'Something went wrong while submitting evaluation. Please try again later.',
      })
      return console.error('Error submitting evaluation form: ', error)
    },
  })

  const currentFormInput = watch()
  const isApplicationRecommendedForApproval =
    currentFormInput.recommendation_recommendedForApprovalOrRejection ===
    EvaluateRecommendationType.Approval
  const isApplicationRecommendedForRejection =
    currentFormInput.recommendation_recommendedForApprovalOrRejection ===
    EvaluateRecommendationType.Rejection

  const rejectionQuestionsKeys = [
    'recommendation_reasonForRejection',
    'recommendation_rejection_remarks',
    'poDeclaration_declaration',
  ]
  // Check if there are errors in the form relating to Rejection questions
  const hasRejectionErrors = Object.keys(errors).some((key) =>
    rejectionQuestionsKeys.includes(key),
  )
  // Check if there are errors in the form relating to Approval questions
  const hasApprovalErrors = Object.keys(errors).some(
    (key) =>
      !rejectionQuestionsKeys
        .filter((key) => key !== 'poDeclaration_declaration')
        .includes(key),
  )
  // For either the Rejection/Approval questions, check if there are relevant errors.
  const hasErrors = isApplicationRecommendedForApproval
    ? hasApprovalErrors
    : isApplicationRecommendedForRejection
    ? hasRejectionErrors
    : false

  const onSubmit = (data: EvaluateFormInputs) => {
    if (hasErrors) {
      return
    }
    return submitEvaluationMutation.mutate(data)
  }

  return (
    <Box pb="40px">
      <FormProvider {...formMethods}>
        <form onSubmit={(e) => void handleSubmit(onSubmit)(e)} noValidate>
          <Recommendation />
          {isApplicationRecommendedForApproval && (
            <ApprovalQuestionList applicationInfo={applicationInfo} />
          )}
          {isApplicationRecommendedForRejection && <RejectionQuestionList />}
          {(isApplicationRecommendedForApproval ||
            isApplicationRecommendedForRejection) && <Declaration />}
          <Button
            type="submit"
            mt="24px"
            isLoading={submitEvaluationMutation.isLoading}
            isDisabled={
              (!isApplicationRecommendedForApproval &&
                !isApplicationRecommendedForRejection) ||
              hasErrors
            }
          >
            Submit Evaluation
          </Button>
          {hasErrors && (
            <Text
              textColor={'interaction.critical.default'}
              mt="8px"
              textStyle={'body-2'}
            >
              * Please address all errors in the form before submitting
            </Text>
          )}
        </form>
      </FormProvider>
    </Box>
  )
}
