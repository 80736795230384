import { Box, ListItem, Text, Tooltip, UnorderedList } from '@chakra-ui/react'
import { PropsWithChildren } from 'react'

import { ReworkTemplatesItem } from '~shared/types/application.dto'

type ReworkItemToolTipProps = {
  item: ReworkTemplatesItem
}

const TooltipLabel = ({
  templateReply,
  templateReplySubpoints,
}: {
  templateReply: string
  templateReplySubpoints?: string[]
}) => {
  return (
    <>
      <Text>{templateReply}</Text>
      {templateReplySubpoints && (
        <UnorderedList>
          {templateReplySubpoints.map((subpoint) => (
            <ListItem key={subpoint}>{subpoint}</ListItem>
          ))}
        </UnorderedList>
      )}
    </>
  )
}

export const ReworkItemToolTip = ({
  item,
  children,
}: PropsWithChildren<ReworkItemToolTipProps>) => {
  const { templateReply, templateReplySubpoints } = item

  return (
    <Tooltip
      label={
        <TooltipLabel
          templateReply={templateReply}
          templateReplySubpoints={templateReplySubpoints}
        />
      }
      placement="top-start"
      hasArrow
      openDelay={1000}
    >
      <Box>{children}</Box>
    </Tooltip>
  )
}
