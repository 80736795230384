import {
  ActivityType,
  ReworkTemplateCategories,
} from '../constants/application'
import { CurrentUser } from './zendeskUser.dto'

export interface FetchApplicationInfoDto {
  ticketId: number
}

export type ThirdPartyVendor = {
  vendorRegisteredIn: string
  exchangeRate: string
  vendorNameOrUen: string
  currency: string
  amount: string
  applicantRemarks?: string
}

export type POEvaluationCostBreakdown = {
  qualifyingAmt: string
  supportLevel: string
  poRemarks: string
}

export type POEvaluationCostBreakdownThirdPartyVendor =
  POEvaluationCostBreakdown & ThirdPartyVendor

export type POEvaluationCostBreakdownThirdPartyVendors =
  POEvaluationCostBreakdownThirdPartyVendor[]

export type OfficeSpaceRental = {
  exchangeRate: string
  description: string
  currency: string
  monthlyCost: string
  durationInMonths: string
}

export type POEvaluationOfficeSpaceRental = POEvaluationCostBreakdown &
  OfficeSpaceRental

export type POEvaluationOfficeSpaceRentals = POEvaluationOfficeSpaceRental[]

export type Salary = {
  name: string
  designation: string
  exchangeRate: string
  nationalityType: string
  currency: string
  monthlySalary: string
  durationInMonths: string
}

export type POEvaluationCostBreakdownSalary = POEvaluationCostBreakdown & Salary

export type POEvaluationCostBreakdownSalaries =
  POEvaluationCostBreakdownSalary[]

export type CostBreakdown = {
  thirdPartyVendors: ThirdPartyVendor[]
  officeSpaceRentals: OfficeSpaceRental[]
  salaries: Salary[]
}

export type ContactPerson = {
  name: string
  jobTitle: string
  contactNo: string
  primaryEmail: string
  secondaryEmails: string[]
}

export type OverseasSales = {
  currentYear: string
  firstYear: string
  secondYear: string
  thirdYear: string
}

export type OverseasInvestment = {
  currentYear: string
  firstYear: string
  secondYear: string
  thirdYear: string
}

export type BusinessImpact = {
  FYEndDate: string
  overseasSales: OverseasSales
  overseasInvestments: OverseasInvestment
  rationaleForProjections: string
  nonTangibleBenefits: string
}

export type Declaration = {
  isInvestigatedOrChargedOrDisciplinaryProceedings: string
  isInvestigatedOrChargedOrDisciplinaryProceedings_disclose: string
  isEngagedInCivilSuitOrProceedings: string
  isEngagedInCivilSuitOrProceedings_disclosure: string
  isBankruptOrSubjectToBankrupt: string
  isBankruptOrSubjectToBankrupt_disclosure: string
  hasAppliedOtherESGGrants: string
  hasAppliedOtherESGGrants_disclosure: string
  hasAppliedOtherIncentivesOrConcessions: string
  hasAppliedOtherIncentivesOrConcessions_disclosure: string
  hasCommencedOnProject: string
  hasCommencedOnProject_disclosure: string
  hasRelationshipOrConnectionWithSuppliersOrProviders: string
  hasRelationshipOrConnectionWithSuppliersOrProviders_disclosure: string
  hasBeenSubjectToDebarment: string
  hasBeenSubjectToDebarment_disclosure: string
}

export interface FetchApplicationInfoResp {
  applicationRefId: string
  applicationApplyDate: string
  applicationApplyDateUnix: string
  applicantNRIC: string
  businessImpact: BusinessImpact
  companyName: string
  companyUen: string
  companyAddress: string
  companyWebsite: string
  companyAnnualSalesTurnover: string
  contactPerson: ContactPerson
  projectName: string
  projectStartDate: string
  projectEndDate: string
  projectDescription: string
  projectRemarks: string
  activityType: ActivityType
  targetMarket: string
  costBreakdown: CostBreakdown
  lofAddresseeName: string
  lofAddresseeJobTitle: string
  lofAddresseeContactNo: string
}

export enum EvaluateRecommendationType {
  Approval = 'Approval',
  Rejection = 'Rejection',
}

export type EvaluateFormInputs = {
  company_hasSufficientLocalEquity: string
  company_isNotLimitedByGuaranteeAndNotInvestmentHoldingCoy: string
  company_remarks: string
  eligibility_groupSalesTurnoverAndEmploymentSizeAcceptable: string
  eligibility_applicantHasNotAppliedForOtherGrantsForSameScopeOfWork: string
  eligibility_applicantOrVendorNotOnWatchListOrBlackListOrAlertList: string
  eligibility_remarks: string
  proposal_submittedDocsTallyWithApplicationForm: string
  proposal_projectFallsWithinMRASupportableActivities: string
  proposal_tradeShowsIsNotSupportedUnderLEADiFMAndBoothSizeAcceptable: string
  proposal_qualifyingStartDate: string
  proposal_qualifyingEndDate: string
  proposal_remarks: string
  cost_breakdown: string
  cost_breakdown_obj: {
    thirdPartyVendors: POEvaluationCostBreakdownThirdPartyVendors
    salaries: POEvaluationCostBreakdownSalaries
    officeSpaceRentals: POEvaluationOfficeSpaceRentals
  }
  costSummary_finalGrantAmount: string
  costSummary_bypassGrantCap: boolean
  deliverables: string
  recommendation_noIssueForApprovingAuthorityToTakeNote: string
  recommendation_recommendedForApprovalOrRejection: EvaluateRecommendationType
  recommendation_reasonForRejection: string
  recommendation_approval_remarks: string
  recommendation_rejection_remarks: string
  recommendation_claimDueDate: string
  recommendation_customNotes: string
  poDeclaration_declaration: boolean
}

export type SubmitEvaluationDto = {
  ticketId: number
  currentUser: CurrentUser
} & EvaluateFormInputs

export type FetchEvaluationResp = {
  evaluationForm: EvaluateFormInputs | undefined
}

export type TemplateReply = {
  templateReply: string // The fixed template reply to be sent to the user
  templateReplySubpoints?: string[] // If the template reply has subpoints, it will be displayed as a list below the template reply
}

export type ReworkTemplatesItem = {
  key: keyof ReworkFormInputs // Use for mapping against which field in React Hook form
  label: string // The questions in the Rework form Checkbox
} & TemplateReply

export type ReworkTemplates = {
  category: ReworkTemplateCategories
  subCategory?: ActivityType // Used to filter out reworks relevant to the activity
  items: ReworkTemplatesItem[]
}[]

export type ReworkFormInputs = {
  company_findOutMore: boolean
  company_acraBusinessFiles: boolean
  company_majorShareholder: boolean
  company_proofOfFinancialCapabilityToCompleteProject: boolean
  financialStatements_notAttached: boolean
  financialStatements_notForPast1Year: boolean
  financialStatements_annualSalesTurnoverDeclaredNotTally: boolean
  quotations_require3Quotations: boolean
  quotations_requireBreakdown: boolean
  quotations_howDidYouKnowVendor: boolean
  quotations_requireVendorCredentials: boolean
  quotations_justificationForAppointingVendor: boolean
  activity_overseasMarketingPresence_requireClarificationOnHolisticProject: boolean
  activity_overseasMarketingPresence_confirmRetrospectiveApplication: boolean
  activity_overseasMarketingPresence_BDPermStaff: boolean
  activity_overseasMarketingPresence_BDUnidentified: boolean
  activity_overseasMarketingPresence_BDStaffNote: boolean
  activity_overseasMarketingPresence_projectDeliverables: boolean
  activity_inMarketBusinessDevelopment_confirmKPI: boolean
  activity_inMarketBusinessDevelopment_projectDeliverables: boolean
  activity_tradeFairs_supportingDocuments: boolean
  activity_tradeFairs_revisedQuotation: boolean
  activity_tradeFairs_projectDeliverables: boolean
  activity_marketingAndPRActivities_revisedQuotation: boolean
  activity_identificationOfPotentialPartners_revisedQuotation: boolean
  activity_identificationOfPotentialPartners_lofDeliverables: boolean
  activity_marketEntry_revisedQuotation: boolean
  activity_marketEntry_trademark: boolean
  activity_marketEntry_incorporationOfOffice: boolean
  activity_marketEntry_JVFranchiseAgreement: boolean
  activity_marketEntry_noteEnglishDocuments: boolean
  activity_marketEntry_projectDeliverables: boolean
}
