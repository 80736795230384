import { VStack } from '@chakra-ui/react'
import { Button, useToast } from '@opengovsg/design-system-react'
import { useMutation } from '@tanstack/react-query'
import { useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { MdCheck, MdCollectionsBookmark } from 'react-icons/md'

import { LoadingIndicator } from '~components/LoadingIndicator'
import { FdpInformation } from '~features/zendesk-home/tabs/Fdp/FdpInformation'
import { FdpInsightsQuery } from '~features/zendesk-home/tabs/Fdp/FdpInsightsQuery'
import { RegenerateAutomatedChecks } from '~features/zendesk-home/tabs/Fdp/RegenerateAutomatedChecks'
import { useZendeskClient } from '~lib/zendeskClient'
import { FetchApplicationInfoResp } from '~shared/types/application.dto'
import { FdpInsightsResp } from '~shared/types/fdp.dto'
import { isUenValid } from '~shared/utils/uenValidation'

type FdpTabProps = {
  applicationInfo: FetchApplicationInfoResp
}
export type FdpFormInputs = {
  applicantUen: string
  applicantNric: string
  targetUenList: string[]
}
export const FdpTab = ({ applicationInfo }: FdpTabProps): JSX.Element => {
  const toast = useToast({ position: 'bottom', isClosable: true })

  const [justPosted, setJustPosted] = useState<boolean>(false)
  const [fdpData, setFdpData] = useState<FdpInsightsResp>()
  const { ticketId, zendeskRequest, currentUser } = useZendeskClient()
  const getFdpInsightsMutation = useMutation({
    mutationFn: async (form: FdpFormInputs) => {
      const params: {
        applicantUen: string
        applicantNric: string
        list: string
      } = {
        applicantUen: form.applicantUen,
        applicantNric: form.applicantNric,
        list: '',
      }
      if (form.targetUenList) {
        params.list = form.targetUenList.filter((t) => isUenValid(t)).join(',')
      }
      const searchParams = new URLSearchParams(params)
      return zendeskRequest<FdpInsightsResp>(
        `/application/fdp-insights?${searchParams.toString()}`,
        'GET',
      )
    },
    onSuccess: (data) => {
      setFdpData(data)
    },
    onError: (error) => {
      toast({
        status: 'error',
        description:
          'Something went wrong while getting FDP insights. Please try again later.',
      })
      return console.error('Error getting FDP insights: ', error)
    },
  })

  const postFdpInsightsMutation = useMutation({
    mutationFn: async () => {
      if (!ticketId) {
        toast({
          status: 'error',
          description:
            'Something went wrong while posting insights. Please refresh the page.',
        })
        return
      }
      return zendeskRequest(
        `/application/${ticketId}/fdp-internal-note`,
        'POST',
        { fdpInsightsResp: fdpData, currentUser },
      )
    },
    onSuccess: () => {
      toast({
        status: 'success',
        description: 'Successfully posted FDP insights as an internal note',
      })
      setJustPosted(true)
      setTimeout(() => {
        setJustPosted(false)
      }, 5000)
    },
    onError: (error) => {
      toast({
        status: 'error',
        description:
          'Something went wrong while posting insights. Please try again later.',
      })
      return console.error('Error posting FDP insights: ', error)
    },
  })

  const regenerateAutomatedChecksMutation = useMutation({
    mutationFn: async () => {
      if (!ticketId) {
        toast({
          status: 'error',
          description:
            'Something went wrong while regenerating automated checks. Please refresh the page.',
        })
        return
      }
      return zendeskRequest(
        `/application/${ticketId}/regenerate-automated-checks`,
        'POST',
        { currentUser },
      )
    },
    onSuccess: () => {
      toast({
        status: 'success',
        description:
          'Successfully regenerated automated checks as an internal note',
      })
    },
    onError: (error) => {
      toast({
        status: 'error',
        description:
          'Something went wrong while regenerating automated checks. Please try again later.',
      })
      return console.error('Error regenerating automated checks: ', error)
    },
  })
  const formMethods = useForm<FdpFormInputs>({
    mode: 'onChange',
    defaultValues: {
      applicantUen: applicationInfo.companyUen,
      applicantNric: applicationInfo.applicantNRIC,
      targetUenList: [],
    },
  })

  const postFdpInsights = () => {
    postFdpInsightsMutation.mutate()
  }

  return (
    <VStack spacing={4} p={'10px'} w={'100%'}>
      <FormProvider {...formMethods}>
        <FdpInsightsQuery getFdpInsightsMutation={getFdpInsightsMutation} />
      </FormProvider>
      {getFdpInsightsMutation.isLoading ? (
        <LoadingIndicator />
      ) : (
        fdpData && (
          <>
            <FdpInformation fdpData={fdpData} />
            <Button
              type={'submit'}
              size={'xs'}
              transition={'all 0.5s ease'}
              style={justPosted ? { backgroundColor: 'green' } : {}}
              leftIcon={justPosted ? <MdCheck /> : <MdCollectionsBookmark />}
              onClick={postFdpInsights}
              isDisabled={justPosted}
              isLoading={postFdpInsightsMutation.isLoading}
            >
              {justPosted ? 'Successfully posted' : `Post FDP Insights`}
            </Button>
          </>
        )
      )}
      <RegenerateAutomatedChecks
        regenerateAutomatedChecksMutation={regenerateAutomatedChecksMutation}
      />
    </VStack>
  )
}
