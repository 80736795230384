import { Box, HStack, Text, Textarea, VStack } from '@chakra-ui/react'
import { SingleSelect, Tooltip } from '@opengovsg/design-system-react'
import { useMutation } from '@tanstack/react-query'
import { Controller, useFormContext } from 'react-hook-form'
import { FaQuestionCircle } from 'react-icons/fa'

import { LoadingIndicator } from '~components/LoadingIndicator'
import { EXCHANGE_RATE_TOOLTIP } from '~features/zendesk-home/tabs/Evaluation/constant'
import { CostInput } from '~features/zendesk-home/tabs/Evaluation/CostBreakdown/CostInput'
import { useZendeskClient } from '~lib/zendeskClient'
import { CURRENCY_LIST } from '~shared/constants/currency'
import {
  EvaluateFormInputs,
  OfficeSpaceRental,
  Salary,
  ThirdPartyVendor,
} from '~shared/types/application.dto'
import { FetchFxRatesResp } from '~shared/types/fx.dto'
import {
  calculateGrantAmount,
  getEquivalentInCurrency,
} from '~shared/utils/number'
import { updateArrayAtIndex } from '~shared/utils/object'
import {
  formatNumberAsSingaporeCurrency,
  getCurrencyCodeFromCurrency,
} from '~shared/utils/string'

import { EditableSingleSelect } from './Edit/EditableSingleSelect'

export interface QualifyingCostCalculationProps {
  formFieldName:
    | 'cost_breakdown_obj.thirdPartyVendors'
    | 'cost_breakdown_obj.salaries'
    | 'cost_breakdown_obj.officeSpaceRentals'
  exchangeRate: string
  currency: string
  rowIndex: number
  isEditing: boolean
  onEditFieldChange: (
    rowIndex: number,
    newValue: Partial<ThirdPartyVendor | Salary | OfficeSpaceRental>,
  ) => void
  applicationDate: string
}

export const QualifyingCostCalculation = ({
  formFieldName,
  exchangeRate,
  currency,
  rowIndex,
  isEditing,
  onEditFieldChange,
  applicationDate,
}: QualifyingCostCalculationProps) => {
  const { zendeskRequest } = useZendeskClient()
  const { control, watch } = useFormContext<EvaluateFormInputs>()
  const nativeCurrency = getCurrencyCodeFromCurrency(currency)
  const qcRow = watch(formFieldName)

  const fetchExchangeRateMutation = useMutation({
    mutationFn: async (currency: string) => {
      const rates = await zendeskRequest<FetchFxRatesResp>(
        `/fx/rates?base=${getCurrencyCodeFromCurrency(
          currency,
        )}&date_time=${applicationDate}`,
        'GET',
      )
      return { rate: rates.quotes[0], currency }
    },
    onSuccess: ({ rate, currency }) => {
      onEditFieldChange(rowIndex, {
        currency,
        exchangeRate: rate.average_midpoint,
      })
    },
  })

  return (
    <VStack w="100%">
      <HStack gap={0} justifyContent={'space-between'} w={'100%'}>
        <Text textStyle={'subhead-2'}>Currency</Text>
        <EditableSingleSelect
          name={`${formFieldName}_currency`}
          isEditing={isEditing}
          items={CURRENCY_LIST}
          value={currency}
          onInputChange={(newCurrency) => {
            fetchExchangeRateMutation.mutate(newCurrency)
          }}
          placeholder={'Select a currency'}
          isDisabled={fetchExchangeRateMutation.isLoading}
          textStyle={'subhead-2'}
        />
      </HStack>
      <HStack
        gap={0}
        justifyContent={'space-between'}
        w={'100%'}
        textAlign={'start'}
      >
        <Text textStyle={'subhead-2'}>Qualifying Cost</Text>
        <Controller
          name={formFieldName}
          control={control}
          rules={{
            validate: (value) => {
              if (value.some((v) => v.qualifyingAmt === '')) {
                return 'Please enter a Qualifying Cost.'
              }
            },
          }}
          render={({ field }) => (
            <CostInput
              {...field}
              currencyCode={nativeCurrency}
              size="xs"
              w={'100px'}
              onChange={(e) => {
                field.onChange(
                  updateArrayAtIndex(field.value, rowIndex, {
                    ...field.value[rowIndex],
                    qualifyingAmt: e,
                  }),
                )
              }}
              value={field.value[rowIndex]?.qualifyingAmt}
            />
          )}
        />
      </HStack>
      <HStack justifyContent={'space-between'} w={'100%'}>
        <HStack>
          <Text textStyle={'subhead-2'}>Exchange Rate</Text>
          <Tooltip label={EXCHANGE_RATE_TOOLTIP}>
            <FaQuestionCircle fontSize={'12px'} />
          </Tooltip>
        </HStack>
        {fetchExchangeRateMutation.isLoading ? (
          <LoadingIndicator customText="" size="xs" py={0} />
        ) : (
          <Text textStyle={'subhead-2'}>{exchangeRate}</Text>
        )}
      </HStack>
      <HStack justifyContent={'space-between'} w={'100%'} textAlign={'start'}>
        <Text textStyle={'subhead-2'}> Final Qualifying Cost</Text>
        <Text textStyle={'subhead-2'}>
          ≈{' '}
          {formatNumberAsSingaporeCurrency(
            getEquivalentInCurrency(
              qcRow[rowIndex].qualifyingAmt,
              exchangeRate,
            ),
          )}
        </Text>
      </HStack>
      <HStack justifyContent={'space-between'} w={'100%'}>
        <Text textStyle={'subhead-2'}>Support Level %</Text>
        <Controller
          name={formFieldName}
          control={control}
          render={({ field }) => (
            <Box w={'100px'}>
              <SingleSelect
                {...field}
                isClearable={false}
                size={'xs'}
                items={
                  formFieldName === 'cost_breakdown_obj.thirdPartyVendors'
                    ? [
                        { label: '0%', value: '0' },
                        { label: '50%', value: '50' },
                      ]
                    : [
                        { label: '0%', value: '0' },
                        { label: '30%', value: '30' },
                        { label: '50%', value: '50' },
                      ]
                }
                placeholder={''}
                onChange={(e) => {
                  field.onChange(
                    updateArrayAtIndex(field.value, rowIndex, {
                      ...field.value[rowIndex],
                      supportLevel: e,
                    }),
                  )
                }}
                value={field.value[rowIndex]?.supportLevel}
              />
            </Box>
          )}
        />
      </HStack>
      <HStack justifyContent={'space-between'} w={'100%'}>
        <Text textStyle={'subhead-2'}>Grant Amount</Text>
        <Text textStyle={'subhead-2'} fontWeight={'700'}>
          {formatNumberAsSingaporeCurrency(
            calculateGrantAmount(
              getEquivalentInCurrency(
                qcRow[rowIndex].qualifyingAmt,
                exchangeRate,
              ).toString(),
              qcRow[rowIndex].supportLevel,
            ),
          )}
        </Text>
      </HStack>

      <Controller
        name={formFieldName}
        control={control}
        render={({ field }) => (
          <Textarea
            {...field}
            size="sm"
            placeholder="PO Remarks (optional)"
            onChange={(e) => {
              field.onChange(
                updateArrayAtIndex(field.value, rowIndex, {
                  ...field.value[rowIndex],
                  poRemarks: e.target.value,
                }),
              )
            }}
            value={field.value[rowIndex]?.poRemarks}
            flex={1}
          />
        )}
      />
    </VStack>
  )
}
