import { TextareaProps } from '@chakra-ui/react'
import { ReactNode } from 'react'

import {
  EvaluateFormInputs,
  FetchApplicationInfoResp,
} from '~shared/types/application.dto'

export enum Groups {
  COMPANY = 'Company',
  ELIGIBILITY = 'Eligibility',
  PROPOSAL = 'Proposal',
  COST = 'Cost',
  DELIVERABLES = 'Deliverables',
  RECOMMENDATION = 'Recommendation',
}

export type Question = {
  id: keyof EvaluateFormInputs
  label: string | ReactNode
  type:
    | 'textarea'
    | 'true_false'
    | 'custom_radio'
    | 'date'
    | 'checkbox'
    | 'cost_breakdown'
    | 'deliverables'
    | 'recommendation_approval_remarks'
    | 'multiselect'
  group: Groups // Which group the question belongs to
  shouldHide?: (data: {
    formInputs: EvaluateFormInputs
    applicationData: FetchApplicationInfoResp
  }) => boolean // Function to determine if the question should be hidden. Returns true if the question should be hidden, else by default, it is shown.
  optional?: boolean // True if the question is optional and not a required field.
  customProps?: {
    textarea?: TextareaProps
    custom_radio?: {
      radioSelections: string[]
      isVertical?: boolean
    }
    checkbox?: {
      text: string | ReactNode
    }
    multiSelect?: {
      items: (data: unknown) => string[]
    }
  } // custom props to pass down to the field component
}
