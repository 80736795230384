import { Text, TextProps } from '@chakra-ui/react'
import { PropsWithChildren } from 'react'

export const BulletPoint = ({
  children,
  ...textProps
}: PropsWithChildren<TextProps>) => {
  return (
    <Text display={'flex'} pl="20px" {...textProps} textStyle={'body-1'}>
      <Text>•</Text>
      <Text ml={2}>{children}</Text>
    </Text>
  )
}
