export enum CurrencyCode {
  USD = 'USD',
  CAD = 'CAD',
  EUR = 'EUR',
  AED = 'AED',
  AFN = 'AFN',
  ALL = 'ALL',
  AMD = 'AMD',
  ARS = 'ARS',
  AUD = 'AUD',
  AZN = 'AZN',
  BAM = 'BAM',
  BDT = 'BDT',
  BGN = 'BGN',
  BHD = 'BHD',
  BIF = 'BIF',
  BND = 'BND',
  BOB = 'BOB',
  BRL = 'BRL',
  BWP = 'BWP',
  BYN = 'BYN',
  BZD = 'BZD',
  CDF = 'CDF',
  CHF = 'CHF',
  CLP = 'CLP',
  CNY = 'CNY',
  COP = 'COP',
  CRC = 'CRC',
  CVE = 'CVE',
  CZK = 'CZK',
  DJF = 'DJF',
  DKK = 'DKK',
  DOP = 'DOP',
  DZD = 'DZD',
  EEK = 'EEK',
  EGP = 'EGP',
  ERN = 'ERN',
  ETB = 'ETB',
  GBP = 'GBP',
  GEL = 'GEL',
  GHS = 'GHS',
  GNF = 'GNF',
  GTQ = 'GTQ',
  HKD = 'HKD',
  HNL = 'HNL',
  HRK = 'HRK',
  HUF = 'HUF',
  IDR = 'IDR',
  ILS = 'ILS',
  INR = 'INR',
  IQD = 'IQD',
  IRR = 'IRR',
  ISK = 'ISK',
  JMD = 'JMD',
  JOD = 'JOD',
  JPY = 'JPY',
  KES = 'KES',
  KHR = 'KHR',
  KMF = 'KMF',
  KRW = 'KRW',
  KWD = 'KWD',
  KZT = 'KZT',
  LBP = 'LBP',
  LKR = 'LKR',
  LTL = 'LTL',
  LVL = 'LVL',
  LYD = 'LYD',
  MAD = 'MAD',
  MDL = 'MDL',
  MGA = 'MGA',
  MKD = 'MKD',
  MMK = 'MMK',
  MOP = 'MOP',
  MUR = 'MUR',
  MXN = 'MXN',
  MYR = 'MYR',
  MZN = 'MZN',
  NAD = 'NAD',
  NGN = 'NGN',
  NIO = 'NIO',
  NOK = 'NOK',
  NPR = 'NPR',
  NZD = 'NZD',
  OMR = 'OMR',
  PAB = 'PAB',
  PEN = 'PEN',
  PHP = 'PHP',
  PKR = 'PKR',
  PLN = 'PLN',
  PYG = 'PYG',
  QAR = 'QAR',
  RON = 'RON',
  RSD = 'RSD',
  RUB = 'RUB',
  RWF = 'RWF',
  SAR = 'SAR',
  SDG = 'SDG',
  SEK = 'SEK',
  SGD = 'SGD',
  SOS = 'SOS',
  SYP = 'SYP',
  THB = 'THB',
  TND = 'TND',
  TOP = 'TOP',
  TRY = 'TRY',
  TTD = 'TTD',
  TWD = 'TWD',
  TZS = 'TZS',
  UAH = 'UAH',
  UGX = 'UGX',
  UYU = 'UYU',
  UZS = 'UZS',
  VEF = 'VEF',
  VND = 'VND',
  XAF = 'XAF',
  XOF = 'XOF',
  YER = 'YER',
  ZAR = 'ZAR',
  ZMK = 'ZMK',
  ZWL = 'ZWL',
}

export const CURRENCY_LIST = [
  'Afghan afghani - AFN',
  'Albanian lek - ALL',
  'Algerian dinar - DZD',
  'Angolan kwanza - AOA',
  'Argentine peso - ARS',
  'Armenian dram - AMD',
  'Aruban florin - AWG',
  'Australian dollar - AUD',
  'Azerbaijani manat - AZN',
  'Bahraini dinar - BHD',
  'Bahamian dollar - BSD',
  'Bangladeshi taka - BDT',
  'Barbadian dollar - BBD',
  'Belarusian ruble - BYN',
  'Belize dollar - BZD',
  'Bermudian dollar - BMD',
  'Bhutanese ngultrum - BTN',
  'Bolivian boliviano - BOB',
  'Bosnia and Herzegovina convertible mark - BAM',
  'Botswana pula - BWP',
  'Brazilian real - BRL',
  'British pound - GBP',
  'Brunei dollar - BND',
  'Burmese kyat - MMK',
  'Burundian franc - BIF',
  'Cambodian riel - KHR',
  'Canadian dollar - CAD',
  'Cape Verdean escudo - CVE',
  'Cayman Islands dollar - KYD',
  'Central African CFA franc - XAF',
  'CFP franc - XPF',
  'Chilean peso - CLP',
  'Chinese yuan - CNY',
  'Colombian peso - COP',
  'Comorian franc - KMF',
  'Congolese franc - CDF',
  'Costa Rican colón - CRC',
  'Croatian kuna - HRK',
  'Cuban convertible peso - CUC',
  'Cuban peso - CUP',
  'Czech koruna - CZK',
  'Danish krone - DKK',
  'Dominican peso - DOP',
  'Djiboutian franc - DJF',
  'Eastern Caribbean dollar - XCD',
  'Egyptian pound - EGP',
  'Eritrean nakfa - ERN',
  'Ethiopian birr - ETB',
  'Euro - EUR',
  'Falkland Islands pound - FKP',
  'Fijian dollar - FJD',
  'Gambian dalasi - GMD',
  'Georgian lari - GEL',
  'Ghanaian cedi - GHS',
  'Gibraltar pound - GIP',
  'Guatemalan quetzal - GTQ',
  'Guinean franc - GNF',
  'Guyanese dollar - GYD',
  'Haitian gourde - HTG',
  'Honduran lempira - HNL',
  'Hong Kong dollar - HKD',
  'Hungarian forint - HUF',
  'Icelandic króna - ISK',
  'Indian rupee - INR',
  'Indonesian rupiah - IDR',
  'Iranian rial - IRR',
  'Iraqi dinar - IQD',
  'Israeli new shekel - ILS',
  'Jamaican dollar - JMD',
  'Japanese yen - JPY',
  'Jordanian dinar - JOD',
  'Kazakhstani tenge - KZT',
  'Kenyan shilling - KES',
  'Kyrgyzstani som - KGS',
  'Kuwaiti dinar - KWD',
  'Lao kip - LAK',
  'Lebanese pound - LBP',
  'Lesotho loti - LSL',
  'Liberian dollar - LRD',
  'Libyan dinar - LYD',
  'Macanese pataca - MOP',
  'Macedonian denar - MKD',
  'Malagasy ariary - MGA',
  'Malawian kwacha - MWK',
  'Malaysian ringgit - MYR',
  'Maldivian rufiyaa - MVR',
  'Mauritanian ouguiya - MRU',
  'Mauritian rupee - MUR',
  'Mexican peso - MXN',
  'Moldovan leu - MDL',
  'Mongolian tögrög - MNT',
  'Moroccan dirham - MAD',
  'Mozambican metical - MZN',
  'Namibian dollar - NAD',
  'Nepalese rupee - NPR',
  'Netherlands Antillean guilder - ANG',
  'New Taiwan dollar - TWD',
  'New Zealand dollar - NZD',
  'Nicaraguan córdoba - NIO',
  'Nigerian naira - NGN',
  'North Korean won - KPW',
  'Norwegian krone - NOK',
  'Omani rial - OMR',
  'Pakistani rupee - PKR',
  'Panamanian balboa - PAB',
  'Papua New Guinean kina - PGK',
  'Paraguayan guaraní - PYG',
  'Peruvian sol - PEN',
  'Philippine peso - PHP',
  'Polish złoty - PLN',
  'Qatari riyal - QAR',
  'Romanian leu - RON',
  'Russian ruble - RUB',
  'Rwandan franc - RWF',
  'Saint Helena pound - SHP',
  'Samoan tālā - WST',
  'São Tomé and Príncipe dobra - STN',
  'Saudi riyal - SAR',
  'Serbian dinar - RSD',
  'Sierra Leonean leone - SLL',
  'Singapore dollar - SGD',
  'Somali shilling - SOS',
  'South African rand - ZAR',
  'South Korean won - KRW',
  'Surinamese dollar - SRD',
  'Swedish krona - SEK',
  'Swiss franc - CHF',
  'Sri Lankan rupee - LKR',
  'Swazi lilangeni - SZL',
  'Syrian pound - SYP',
  'Tajikistani somoni - TJS',
  'Tanzanian shilling - TZS',
  'Thai baht - THB',
  'Tongan paʻanga - TOP',
  'Trinidad and Tobago dollar - TTD',
  'Tunisian dinar - TND',
  'Turkish lira - TRY',
  'Turkmenistan manat - TMT',
  'Ugandan shilling - UGX',
  'Ukrainian hryvnia - UAH',
  'United Arab Emirates dirham - AED',
  'United States dollar - USD',
  'Uruguayan peso - UYU',
  'Uzbekistani soʻm - UZS',
  'Vanuatu vatu - VUV',
  'Venezuelan bolívar soberano - VES',
  'Vietnamese đồng - VND',
  'West African CFA franc - XOF',
  'Zambian kwacha - ZMW',
]
