import { useEffect } from 'react'

const FetchAndRenderHTML = ({ url }: { url: string }) => {
  useEffect(() => {
    fetch(url)
      .then((response) => {
        response
          .text()
          .then((html) => {
            // Replace the entire DOM tree
            const parser = new DOMParser()
            const doc = parser.parseFromString(html, 'text/html')
            document.documentElement.innerHTML = doc.documentElement.innerHTML
          })
          .catch(console.error)
      })
      .catch(console.error)
  }, [url])

  return <></>
}

export default FetchAndRenderHTML
