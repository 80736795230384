import 'inter-ui/inter.css'
import '@fontsource/ibm-plex-mono'

import { ThemeProvider } from '@opengovsg/design-system-react'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'

import { theme } from '~/theme'
import { useZendeskClient } from '~lib/zendeskClient'

import { AppRouter } from './AppRouter'

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: false,
    },
  },
})

export const App = (): JSX.Element => {
  // init zendesk client
  useZendeskClient()
  return (
    <QueryClientProvider client={queryClient}>
      <ThemeProvider theme={theme} resetCSS>
        <AppRouter />
      </ThemeProvider>
    </QueryClientProvider>
  )
}
